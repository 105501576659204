import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Url } from '../../enum/url.enum';
import {
  IAddDesignationHierarchyList,
  IUpdateDesignationHierarchy,
} from '../../interface/designation-hierarchy/designation-hierarchy.interface';
import { CommonUtils } from '../../utils/common.utils';
import { HttpRequestService } from '../http-request/http-request.service';
import { UtilitiesService } from '../utilities/utilities.service';

@Injectable({
  providedIn: 'root',
})
export class RoleHierarchyService {
  roleHierarchyAPI = Url.AUTH + '/roles/hierarchy';

  constructor(
    private readonly httpService: HttpRequestService,
    private readonly utilitiesService: UtilitiesService,
  ) {}

  public getRoleHierarchyList(): Observable<any> {
    return this.httpService
      .get(this.roleHierarchyAPI + '/all')
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getRoleHierarchyByProject(projectId: string): Observable<any> {
    return this.httpService.get(this.roleHierarchyAPI + '/' + projectId).pipe(
      map((x: any) => this.utilitiesService.responseHandler(x)),
      map((x: any) => CommonUtils.getSortedListByUpdatedOrCreatedTime(x)),
      map((x: any) => this.utilitiesService.getSlNo(x)),
    );
  }

  public createRoleHierarchy(
    body: IAddDesignationHierarchyList,
  ): Observable<any> {
    return this.httpService
      .post(this.roleHierarchyAPI, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public updateRoleHierarchy(
    id: string,
    body: IUpdateDesignationHierarchy,
  ): Observable<any> {
    return this.httpService
      .put(this.roleHierarchyAPI + '/' + id, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getRoleHierarchyById(id: string): Observable<any> {
    return this.httpService
      .get(this.roleHierarchyAPI + '/getDetails/' + id)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public deleteRoleHierarchy(id: string): Observable<any> {
    return this.httpService
      .delete(this.roleHierarchyAPI + '/' + id)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }


  public getWorkingAreaTypes(project_id: string, is_all_upto_last : boolean = false): Observable<any> {
    return this.httpService
      .get(this.roleHierarchyAPI + '/working-area-types?project_id=' + project_id + '&is_all_upto_last=' + is_all_upto_last)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
}
