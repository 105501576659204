import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../shared/services/auth/auth.service';
import { UtilitiesService } from '../shared/services/utilities/utilities.service';
@Injectable()
export class AdminGuard implements CanActivate {
  constructor(
    private router: Router,
    public authService: AuthService,
    public utilsService: UtilitiesService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    //return true;
    // If path is addStaffmaping
    if (
      route.routeConfig.path === 'addStaffMapping' ||
      route.routeConfig.path === 'staffMapping/add'
    ) {
      if (this.authService.isSMORole() || this.authService.isAdminRole()) {
        return true;
      } else {
        this.router.navigateByUrl('/dashboard');
        //window.location.href = this.globalService.ssoBracUrl;
        return false;
      }
    } else {
      if (this.authService.isAdminRole()) {
        return true;
      } else {
        this.router.navigateByUrl('/dashboard');
        //window.location.href = this.globalService.ssoBracUrl;
        return false;
      }
    }
  }
}
