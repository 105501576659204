import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpRequestService {
  private baseUrl: string = environment.apiURL;

  constructor(private http: HttpClient) {}

  get<T>(apiUrl: string, obj: any = ''): Observable<T> {
    //   const opts = { headers: this.getHeaders(false) };
    //   if (obj) {
    //     Object.assign(opts, { params: new HttpParams({ fromObject: obj }) });
    //   }

    //console.log('opts', opts, obj);
    return this.http.get<T>(this.baseUrl + apiUrl).pipe(
      map((x: any) => x),
      catchError((err) => {
        return throwError(err);
        //return throwError(err);
      }),
    );
  }

  post<T>(apiUrl: string, model: any): Observable<T> {
    //const opts = { headers: this.getHeaders(false) };
    return this.http.post<T>(this.baseUrl + apiUrl, model).pipe(
      catchError((err) => {
        return throwError(err);
      }),
    );
  }

  delete<T>(apiUrl: string, model?: any): Observable<T> {
    //const opts = { headers: this.getHeaders(false)};
    if (model != null) {
      return this.http.delete<T>(this.baseUrl + apiUrl, {body: model}).pipe(
        catchError((err) => {
          return throwError(err);
        }),
      );
    } else {
      return this.http.delete<T>(this.baseUrl + apiUrl).pipe(
        catchError((err) => {
          return throwError(err);
        }),
      );
    }
  } 
  put<T>(apiUrl: string, model: any): Observable<T> {
    //const opts = { headers: this.getHeaders(false) };
    return this.http.put<T>(this.baseUrl + apiUrl, model).pipe(
      catchError((err) => {
        return throwError(err);
      }),
    );
  }

  patch<T>(apiUrl: string, model: any): Observable<T> {
    //const opts = { headers: this.getHeaders(false) };
    return this.http.patch<T>(this.baseUrl + apiUrl, model).pipe(
      catchError((err) => {
        return throwError(err);
      }),
    );
  }

  // postWithFile<T>(apiUrl: string, model: any): Observable<T> {
  //     const opts = { headers: this.getHeaders(true) };
  //     return this.http.post<T>(this.baseUrl + apiUrl, model, opts).pipe(
  //       catchError((err) => {
  //         return throwError(err);
  //       })
  //     );
  //   }
  //   postWithOptions<T>(apiUrl: string, model: T, options: any): Observable<T> {
  //     return this.http.post<T>(this.baseUrl + apiUrl, model, ...options).pipe(
  //       catchError((err) => {
  //         return throwError(err);
  //       })
  //     );
  //   }
  //   postFile<T>(apiUrl: string, model: any): Observable<T> {
  //     const opts = { headers: this.getHeaders(false), responseType: 'blob' as 'json'};
  //     return this.http.post<T>(this.baseUrl + apiUrl, model, opts).pipe(
  //       catchError((err) => {
  //         return throwError(err);
  //       })
  //     );
  //   }
  //   private handleError<T>(operation = 'operation', result?: T) {
  //     return (error: any): Observable<T> => {
  //       console.error(error); // log to console instead
  //       return of(result as T);
  //     };
  //   }

  //   downLoadFile<T>(apiUrl: string, obj: any = ''): Observable<any> {
  //     return this.http.get<T>(this.baseUrl + apiUrl, obj).pipe(
  //       catchError((err) => {
  //         return throwError(err);
  //       })
  //     );
  //   }

  //   uploadFile<T>(apiUrl: string, model: any): Observable<T> {
  //     return this.http.post<T>(this.baseUrl + apiUrl, model).pipe(
  //       catchError((err) => {
  //         return throwError(err);
  //       })
  //     );
  //   }
}
