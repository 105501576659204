import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Url } from '../../enum/url.enum';
import { HttpRequestService } from '../http-request/http-request.service';
import { UtilitiesService } from '../utilities/utilities.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  projectAPI = Url.CONFIGURATION + '/masterdata/project';

  constructor(
    private readonly httpRequestService: HttpRequestService,
    private readonly utilitiesService: UtilitiesService,
  ) {}

  public getProjectByProjectID(projectID: string): Observable<any> {
    return this.httpRequestService
      .get(this.projectAPI + '/getDetails/' + projectID)
      .pipe(
        map((x: any) => this.utilitiesService.responseHandler(x)),
        map((project: any) => {
          project.agreement_signing_document = JSON.parse(
            project.agreement_signing_document,
          );
          project.approval_document = JSON.parse(project.approval_document);
          project.ngo_ab_approval_document = JSON.parse(
            project.ngo_ab_approval_document,
          );
          return project;
        }),
      );
  }

  public getProjectList(): Observable<any> {
    return this.httpRequestService.get(this.projectAPI).pipe(
      map((x: any) => this.utilitiesService.responseHandler(x)),
      map((x: any) =>
        x.sort(this.utilitiesService.dynamicSort('project_name')),
      ),
      map((x: any) => this.utilitiesService.getSlNo(x)),
      map((x: any) =>
        this.utilitiesService.addCustomProperty(x, 'ProjectList'),
      ),
    );
  }

  public createProject(body: Object): Observable<any> {
    return this.httpRequestService
      .post(this.projectAPI, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public updateProject(body: Object): Observable<any> {
    return this.httpRequestService
      .put(this.projectAPI, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public deleteProject(projectID: string): Observable<any> {
    return this.httpRequestService
      .delete(this.projectAPI + '/' + projectID)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
}
