<div class="row">
  <div class="col-sm-12">
    <label class="col-form-label" [ngClass]="{'requiredMark': validators.length > 0}"
      >Select Project:</label>
    <div>
      <dx-select-box
        [dataSource]="projectList"
        valueExpr="id"
        displayExpr="project_name"
        [searchEnabled]="true"
        [searchExpr]="['project_name']"
        [showClearButton]="true"
        [(value)]="value"
        (onSelectionChanged)="valueChanged($event)" 
      >
      <dx-validator [validationGroup]="validationGroupName" [validationRules]="validators"></dx-validator>

      </dx-select-box>
    </div>
  </div>
</div>