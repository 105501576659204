import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { jsPDF } from 'jspdf';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { HttpRequestSuccessFailed } from '../../enum/http-request-success-failed.enum';
import { IErrorResponse } from '../../interface/error-response.interface';
import { LocalStorageService } from '../local-storage/local-storage.service';
@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  isLoginRequest: boolean = false;
  constructor(
    private spinner: NgxSpinnerService,
    public localStorageService: LocalStorageService,
  ) {}

  public sheetTemplateURL = './assets/sheetTemplate/';
  public rowSize = 10;
  public allowedPageSizes = [10, 20, 50, 100, 250, 500, 1000];
  validationRegex = [
    {
      id: '1',
      value: '^[0-9]*$',
      displayName: 'Numeric',
    },
    {
      id: '2',
      value: '^[a-zA-Z ]*$',
      displayName: 'Alphabet',
    },
    {
      id: '3',
      value: '^[a-zA-Z0-9 !@#$%^&*()\\-_=+[\\]{};:\'",.<>/?]*$',
      displayName: 'Alphanumeric',
    },
    {
      id: '4',
      value: '^[+-]?(\\d+\\.?\\d*|\\.\\d+)$',
      displayName: 'Decimal',
    },
  ];
  // '^[a-zA-Z0-9 ]*$'
  projectId_forAdmin = 'd0672c2e-dbd0-4bfb-b35d-078b2ca72d52'; // Wash project
  programId: string = '94d7401e-ed40-475b-8696-6836b2b711e0'; // wash Program
  public showSwal(title: string, message: string, type: any) {
    // type = "success", "error", "warning", "info" or "question"
    // Swal.fire('OPS Sorry', 'Username or Password  not match', 'warning');
    // Swal.fire(title, message, type);
    Swal.fire({
      icon: type,
      title,
      //text: message
      html: message,
      allowOutsideClick: false,
    });
  }

  public showSwalWithToast(
    title: string = 'Success',
    message: string = 'Request successful.',
    type: 'success' | 'error' | 'warning' | 'info' | 'question' = 'success',
    timerProgressBar: boolean = true,
    timer: number = 2000,
    showConfirmButton: boolean = false,
    showCloseButton: boolean = true,
  ) {
    Swal.fire({
      toast: true,
      position: 'top-end',
      showCloseButton: showCloseButton,
      showConfirmButton: showConfirmButton,
      icon: type,
      title,
      html: message,
      allowOutsideClick: false,
      timerProgressBar: timerProgressBar,
      timer: timer,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
        toast.addEventListener('click', () => Swal.close());
      },
    });
  }

  public async showSwalAndReturn(params?: {
    title?: string;
    message?: string;
    type?: any;
    confirmButtonText?: string;
    cancelButtonText?: string;
    confirmButtonColor?: string;
    cancelButtonColor?: string;
    showCancelButton?: boolean;
  }) {
    return await Swal.fire({
      title: params?.title || 'Are you sure?',
      text: params?.message || 'You want to delete!',
      icon: params?.type || 'question',
      showCancelButton: params?.showCancelButton || true,
      confirmButtonText: params?.confirmButtonText || 'Yes, delete it!',
      cancelButtonText: params?.cancelButtonText || 'No',
      confirmButtonColor: params?.confirmButtonColor || '#3085d6',
      cancelButtonColor: params?.cancelButtonColor || '#d33',
    });
  }

  public showSpinner(isSpinning: boolean) {
    if (isSpinning) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
  }

  public errorResponseHandler(error: IErrorResponse) {
    if (error.code === 400 || error.code === 404 || error.code === 409) {
      this.showSwalWithToast(error.error_name, error.message, 'warning');
    } else {
      this.showSwalWithToast('Warning', 'Something went wrong', 'error');
    }
  }

  public httpSuccessToastyHandler = (
    responseType: string,
    request: HttpRequest<unknown>,
    event,
  ) => {
    const { method, body, url } = request;
    const message: string = event?.body?.result?.message;
    const isSuccess: boolean =
      (event?.body?.result?.is_success || event?.body?.result?.is_updated) ??
      event?.body?.status === 'ok'
        ? true
        : false;
    responseType = this.toastExcludeHandler(responseType, url);
    responseType === HttpRequestSuccessFailed.SUCCESS
      ? method === 'GET'
        ? null
        : method === 'POST'
          ? body instanceof FormData
            ? null
            : this.showSwalWithToast(
                'Success',
                this.isLoginRequest
                  ? 'Login successful.'
                  : 'Added successfully.',
                'success',
              )
          : body instanceof FormData
            ? null
            : this.showSwalWithToast(
                isSuccess ? 'Success' : 'Failed',
                message,
                isSuccess ? 'success' : 'error',
              )
      : null;
  };

  public responseHandler(result: {
    status: string;
    result: any;
    resultset: any[];
  }) {
    if (result && result.status === 'ok') {
      return result?.resultset ?? result.result;
    }
    return null;
  }

  public getSlNo(result) {
    let slNo = 1;
    result.forEach((element: any) => {
      element.slNo = slNo++;
    });
    return result;
  }

  public addCustomProperty(
    result: any[],
    from: string,
    forHOUser: boolean = false,
  ): any[] {
    switch (from) {
      case 'ProjectList':
        return result.map((item) => ({
          ...item,
          start_custom: this.formatDate(item.start),
          end_custom: this.formatDate(item.end),
          is_active_custom: item.is_active ? 'Active' : 'Inactive',
        }));
      case 'GeneralList':
        return result.map((item) => ({
          ...item,
          is_active_custom: item.is_active ? 'Active' : 'Inactive',
        }));
      case 'ProjectActivityMappingList':
        return result.map((item) => ({
          ...item,
          checklist: {
            ...item.checklist,
            is_active_custom: item.checklist.is_active ? 'Active' : 'Inactive',
          },
        }));
      case 'SubordinateStaffs':
        return result.map((item) => ({
          ...item,
          custom_name:
            item.name +
            (item.role_short_form ? ', ' + item.role_short_form : '') +
            '- ' +
            item.pin,
          custom_name_obj: {
            name:
              item.name +
              (item.role_short_form ? ', ' + item.role_short_form : ''),
            id: forHOUser ? item.id : item.user_id,
          },
        }));
      default:
        return result.map((item) => ({
          ...item,
        }));
    }
  }

  public getFiltered(
    array: any[],
    key: string,
    compValue: any,
    isNotEqual: boolean = false,
  ) {
    return array.filter((data) => {
      if (data && data?.hasOwnProperty(key)) {
        return isNotEqual ? data[key] !== compValue : data[key] === compValue;
      }
      return false;
    });
  }

  public dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a: any, b: any) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  public dynamicSortWithDate(property, sortOrderType = 'asc') {
    var sortOrder = sortOrderType.toLowerCase() === 'desc' ? -1 : 1;

    return function (a, b) {
      var dateA = new Date(a[property]);
      var dateB = new Date(b[property]);

      if (dateA < dateB) return -1 * sortOrder;
      if (dateA > dateB) return 1 * sortOrder;
      return 0;
    };
  }

  public formattedDateddMMyyyy(dateValue: string): string {
    var date = new Date(dateValue);

    // Get year, month, and day part from the date
    var year = date.toLocaleString('default', { year: 'numeric' });
    var month = date.toLocaleString('default', { month: '2-digit' });
    var day = date.toLocaleString('default', { day: '2-digit' });

    // Generate yyyy-mm-dd date string
    var formattedDate = day + '/' + month + '/' + year;

    return formattedDate;
  }

  formatDate(inputDate) {
    const date = new Date(inputDate);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  }

  public getUserLocation() {
    const that = this;
    if ('geolocation' in navigator) {
      // Geolocation is available
      this.showSpinner(true);
      navigator.geolocation.getCurrentPosition(
        function (position) {
          console.log('Latitude: ' + position.coords.latitude);
          console.log('Longitude: ' + position.coords.longitude);
          console.log(position.coords);

          that.showSpinner(false);

          // You can now use the latitude and longitude in your application
          return {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
        },
        function (error) {
          // Handle any errors that occur during geolocation
          that.showSpinner(false);
          that.showSwalWithToast(
            'Location Not Found',
            'Please check your browser location permission enable or not for this website.',
            'warning',
          );

          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.error('User denied the request for Geolocation.');
              break;
            case error.POSITION_UNAVAILABLE:
              console.error('Location information is unavailable.');
              break;
            case error.TIMEOUT:
              console.error('The request to get user location timed out.');
              break;
            // case error.UNKNOWN_ERROR:
            //   console.error("An unknown error occurred.");
            //   break;
          }
        },
      );
    } else {
      // Geolocation is not available in this browser
      this.showSpinner(false);
      console.error('Geolocation is not available in your browser.');
      that.showSwalWithToast(
        'Location Not Found',
        'Geolocation is not available in your browser.',
        'warning',
      );
    }
  }

  public calculateAge(dob: Date | string) {
    const timeDiff = Math.abs(Date.now() - new Date(dob).getTime());
    return Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
  }

  isAddPermission(): boolean {
    let selectedMenu = this.localStorageService.getSelectedMenuInfo();
    if (selectedMenu != null) {
      return selectedMenu?.actions?.create;
    } else {
      return false;
    }
  }

  isEditPermission(): boolean {
    let selectedMenu = this.localStorageService.getSelectedMenuInfo();
    if (selectedMenu != null) {
      return selectedMenu?.actions?.update;
    } else {
      return false;
    }
  }

  isRemovePermission(): boolean {
    let selectedMenu = this.localStorageService.getSelectedMenuInfo();
    if (selectedMenu != null) {
      return selectedMenu?.actions?.remove;
    } else {
      return false;
    }
  }

  isGetPermission(): boolean {
    let selectedMenu = this.localStorageService.getSelectedMenuInfo();
    if (selectedMenu != null) {
      return selectedMenu?.actions?.get;
    } else {
      return false;
    }
  }

  onExportingFromDataGrid(e, fileName) {
    if (e.format === 'pdf') {
      const doc = new jsPDF();
      exportDataGridToPdf({
        jsPDFDocument: doc,
        component: e.component,
      }).then(() => {
        doc.save(fileName + '.pdf');
      });
    } else {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(fileName);

      exportDataGridToXLSX({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            fileName + '.xlsx',
          );
        });
      });
      e.cancel = true;
    }
  }

  private toastExcludeHandler(responseType: string, url: string): string {
    return responseType === HttpRequestSuccessFailed.SUCCESS &&
      (url.includes('account/login') ||
        url.includes('notifications/unregister') ||
        url.includes('notifications/register'))
      ? ''
      : responseType;
  }
}
