<div class="row">
  <div class="col-sm-12">
    <label
      class="col-form-label"
      [ngClass]="{ requiredMark: validators.length > 0 }"
      >{{ label }}:</label
    >
    <div>
      <dx-date-box
        type="date"
        pickerType="calendar"
        displayFormat="MMMM yyyy"
        [showClearButton]="true"
        [(value)]="value"
        [max]="maxDate"
        [min]="minDate"
        (onValueChanged)="valueChanged($event)"
      >
        <dxo-calendar-options
          [zoomLevel]="viewLevel"
          [maxZoomLevel]="maxViewLevel"
        ></dxo-calendar-options>
        <dx-validator
          [validationGroup]="validationGroupName"
          [validationRules]="validators"
        ></dx-validator>
      </dx-date-box>
    </div>
  </div>
</div>
