import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Url } from '../../enum/url.enum';
import { HttpRequestService } from '../http-request/http-request.service';
import { UtilitiesService } from '../utilities/utilities.service';
@Injectable({
  providedIn: 'root',
})
export class ActivityTypeService {
  activityTyypeAPI = Url.CONFIGURATION + '/activity-type/'; 
  public selected: string;

  constructor(
    private readonly httpRequestService: HttpRequestService,
    private readonly utilitiesService: UtilitiesService,
  ) {}

 

  public getActivityTypeList(): Observable<any> {
    return this.httpRequestService.get(this.activityTyypeAPI + 'all').pipe(
      map((x: any) => this.utilitiesService.responseHandler(x)),
      map((x: any) =>
        x.sort(this.utilitiesService.dynamicSort('activity_type_name')),
      ),
      map((x: any) => this.utilitiesService.getSlNo(x))
    );
  }
 
}
