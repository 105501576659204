import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AreaFilterTypeEnum } from '../../enum/area-filter-type.enum';
import { Url } from '../../enum/url.enum';
import {
  IAddProjectOfficeList,
  IUpdateProjectOffice,
} from '../../interface/project-office/project-office.interface';
import { HttpRequestService } from '../http-request/http-request.service';
import { UtilitiesService } from '../utilities/utilities.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectOfficeService {
  projectOfficeAPI = Url.CONFIGURATION + '/project-office-management';

  constructor(
    private readonly httpRequestService: HttpRequestService,
    private readonly utilitiesService: UtilitiesService,
  ) {}

  public getProjectOfficeList(): Observable<any> {
    return this.httpRequestService.get(this.projectOfficeAPI + '/all').pipe(
      map((x: any) => this.utilitiesService.responseHandler(x)),
      map((x: any) =>
        x.sort(this.utilitiesService.dynamicSortWithDate('created_at', 'desc')),
      ),
      map((x: any) => this.utilitiesService.getSlNo(x)),
    );
  }

  public createProjectOffice(body: IAddProjectOfficeList): Observable<any> {
    return this.httpRequestService
      .post(this.projectOfficeAPI + '/add', body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getProjectOfficeById(
    projectId: string,
    districtId: string,
    id: string,
  ): Observable<any> {
    return this.httpRequestService
      .get(
        this.projectOfficeAPI +
          '/details?project_id=' +
          projectId +
          '&district_id=' +
          districtId +
          '&id=' +
          id,
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public updateProjectOffice(
    projectId: string,
    districtId: string,
    id: string,
    body: IUpdateProjectOffice,
  ): Observable<any> {
    return this.httpRequestService
      .patch(
        this.projectOfficeAPI +
          '/update?project_id=' +
          projectId +
          '&district_id=' +
          districtId +
          '&id=' +
          id,
        body,
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public deleteProjectOffice(
    projectId: string,
    districtId: string,
    id: string,
  ): Observable<any> {
    return this.httpRequestService
      .delete(
        this.projectOfficeAPI +
          '/delete?project_id=' +
          projectId +
          '&district_id=' +
          districtId +
          '&id=' +
          id,
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getProjectOfficeAreaList(
    projectId: string,
    filterType: AreaFilterTypeEnum,
    filterValue?: string,
  ) {
    let url =
      this.projectOfficeAPI +
      '/areas?project_id=' +
      projectId +
      '&filter_type=' +
      filterType;
    url = filterValue ? url + '&filter_value=' + filterValue : url;

    return this.httpRequestService
      .get(url)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getDivisionList(projectId: string) {
    let url = this.projectOfficeAPI + '/divisions/' + projectId;

    return this.httpRequestService
      .get(url)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
}
