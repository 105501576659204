export enum Url {
  AUTH = '/wash-auth/api/v1',
  CONFIGURATION = '/wash-configuration/api/v1',
  PS = '/upg-participant-selection/api/v1',
  FILE_UPLOAD = '/wash-files/api/v1',
  MASTER_DATA_UPLOAD = '/masterdata-upload/api/v1',
  FROM_BUILDER = '/wash-formbuilder/api',
  COHORT_CONFIG = '/cohort-configuration/api',
  ENROLLMENT = 'upg-enrollment/api/v1',
  REPORT = '/wash-report/api/v1/reports',
  NOTIFICATION = '/wash-notifications/api/v1',
}
