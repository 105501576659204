import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ProjectService } from '../../services/project/project.service';
import { UtilitiesService } from '../../services/utilities/utilities.service';
import { ValidationRule } from 'devextreme/common';
import { ActivityTypeService } from '../../services/activity-type/activity-type.service';
import { ReportSharedService } from '../../shared-services/report-shared/report-shared-service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-activity-type-list',
  templateUrl: './activity-type-list.component.html',
  styleUrls: ['./activity-type-list.component.css'],
})
export class ActivityTypeListComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  @Input() isEditing = false;

  @Input() text: string;

  @Input() validators: ValidationRule[];

  @Input() validationGroupName: string;

  @Input() value!: any;

  @Output() valueChange = new EventEmitter<any>();
  activityTagList: any[] = [];
  body = {
    activity_types: [],
  };

  valueChanged(e) {
    // console.log(e);
    this.body.activity_types = [];
    e.value.forEach((element) => {
      this.body.activity_types.push({
        id: element.id,
        name: element.activity_type_name,
      });
    });
    this.valueChange.emit(this.body);
  }

  constructor(
    public activityTypeService: ActivityTypeService,
    private sharedService: ReportSharedService,
  ) {
    this.subscription = this.sharedService.methodCall$.subscribe((message) => {
      this.selectAll(message);
    });
  }

  ngOnInit() {
    this.getActivityTagList();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getActivityTagList() {
    this.activityTagList = [];

    this.activityTypeService.getActivityTypeList().subscribe(
      (result) => {
        if (result) {
          this.activityTagList = result;
        }
      },
      (err) => {
        // console.log(err);
      },
    );
  }

  public selectAll(flag: string): void {
    if (flag === '1') {
      this.value = this.activityTagList;
    } else if (flag === '0') {
      this.value = null;
    }
  }
}
