<div class="row">
  <div class="col-12 col-xl-12 mb-4 mb-xl-0">
    <div class="card-body">
      <!-- <h2 class="card-title">Programme</h2> -->
      <!-- <p class="card-description">List Pr</p> -->
      <div class="col-sm-12">
        <div class="row">
        <div class="col-sm-4">
          <div>
            <label class="modal-label requiredMark">Select Project</label> 
          </div>
        </div>
        <div class="col-sm-8">
          <div  >
            <dx-select-box 
              [dataSource]="programList"
              valueExpr="project_id"
              displayExpr="project_name"
              [searchEnabled]="true"
              [searchExpr]="['project_name']"
              [(ngModel)]="projectID"
              (onValueChanged)="projectOptionChange($event)"
              [showClearButton]="true"
              [disabled]="programList.length == 1"
            >
              <dx-validator validationGroup="validationGrp">
                <dxi-validation-rule
                  type="required"
                  message="Project Required"
                ></dxi-validation-rule>
              </dx-validator>
            </dx-select-box>
          </div>
        </div>
        </div>
      
      </div>
      <div class="col-sm-12 mb-4" style="margin-top: 4%; text-align: end;">
        <dx-button
          class="themeButton"
          text="Save"
          (onClick)="onFormSubmit($event)"
          [useSubmitBehavior]="true"
        ></dx-button>
      </div>
    </div>
  </div>
</div>
