import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as XLSX from 'xlsx';
import { FileUploadSubCategory } from '../../enum/file-upload-sub-category.enum';
import { UploaderService } from '../../services/uploader/uploader.service';
import { UtilitiesService } from '../../services/utilities/utilities.service';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.css'],
})
export class BulkUploadComponent implements OnInit {
  @Output() showUploaderClick = new EventEmitter<any>();
  @Input() category: string;
  @Input() subCategory: string;
  @Input() title: string;
  @Input() downloadCSVTemplateLink: string;

  dataSource: any = [];
  columns: any = [];
  fileData: File;
  failedListFound: boolean = false;
  dataSourceFailed: any;

  constructor(
    public utilitiesService: UtilitiesService,
    public uploaderService: UploaderService,
  ) {}

  ngOnInit(): void {
    this.failedListFound = false;
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.onFileChange($event);
    // this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler($event) {
    this.onFileChange($event);
  }

  onFileChange(event: any) {
    this.failedListFound = false;

    /* wire up file reader */
    const target: DataTransfer = event.target as DataTransfer;
    // if (target.files.length !== 1) {
    //   this.utilitiesService.showSwal('Warning!!' ,'Cannot use multiple files', 'warning');
    //   throw new Error('Cannot use multiple files');
    // }

    if (target.files[0]) {
      this.fileData = target.files[0];
      if (this.fileData.name.split('.').pop() != 'csv') {
        this.utilitiesService.showSwal(
          'Warning!!',
          'Please choose only CSV file format.',
          'warning',
        );
      } else {
        this.dataSource = [];
        const reader: FileReader = new FileReader();
        reader.readAsBinaryString(this.fileData);
        reader.onload = (e: any) => {
          /* create workbook */
          const binaryStr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(binaryStr, { type: 'binary' });

          /* selected the first sheet */
          const wsName: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsName];

          /* save data */
          const data = XLSX.utils.sheet_to_json(ws, { raw: false }); // to get 2d array pass 2nd parameter as object {header: 1}
          let slNo = 1;
          this.dataSource = data;
          // this.dataSource.forEach((element) => {
          //   element.slNo = slNo++;
          // });

          if (this.subCategory === 'users') {
            // this.setContactPrefixForUserUpload();
          }

          this.makeColumn();
          // this.columns = []; // Make Column for Grid
          // if (this.dataSource.length != 0) {
          //   const key = Object.keys(this.dataSource[0]);
          //   key.forEach((element) => {
          //     if (element === 'slNo') {
          //       this.columns.unshift(element);
          //     } else {
          //       this.columns.push(element);
          //     }
          //   });
          // }
          console.log(this.dataSource); // Data will be logged in array format containing objects
        };
      }
    }
    // Clear the file picker.
    let fileDropElemRef: any = document.getElementById('fileDropRef');
    fileDropElemRef.value = '';
  }

  public async submitFile(e) {
    if (!this.dataSource.length) {
      this.utilitiesService.showSwal(
        'No file or data was found!',
        'Please select a valid file with data.',
        'warning',
      );
    } else {
      const result = await this.utilitiesService.showSwalAndReturn({
        title: 'Think!',
        message: 'Do you want to upload bulk data?',
        type: 'info',
        confirmButtonText: 'Yes, Upload!',
      });
      if (result.isConfirmed) {
        this.failedListFound = false;
        this.uploaderService
          .uploadMasterDataFile(this.fileData, this.subCategory)
          .subscribe(
            (result) => {
              console.log(result);
              if (
                this.subCategory === FileUploadSubCategory.STAFF_MAPPING ||
                this.subCategory === FileUploadSubCategory.USERS ||
                this.subCategory ===
                  FileUploadSubCategory.BRANCH_SUB_DISTRICT ||
                this.subCategory === FileUploadSubCategory.ZONE_REGION_BRANCH ||
                this.subCategory === FileUploadSubCategory.DISTRICT_ZONE ||
                this.subCategory === FileUploadSubCategory.GEO_LOCATION ||
                this.subCategory ===
                  FileUploadSubCategory.PROJECT_OFFICE_MANAGEMENT ||
                this.subCategory ===
                  FileUploadSubCategory.ADDITIONAL_FIELD_STRUCTURE || 
                  this.subCategory ===
                  FileUploadSubCategory.TARGET_SETUP
              ) {
                // For Jahid vai api, staff mapping,  Users, Branch-subdistrict mapping
                // "resultset": [
                //   { }
                // ]
                if (Array.isArray(result) && result.length > 0) {
                  let failedList = result.filter((s) => s.reason);
                  this.dataSource = [];
                  if (failedList.length > 0) {
                    this.failedListFound = true;
                    this.dataSource = failedList;
                    console.log(this.dataSource);
                    this.makeColumn();
                    this.utilitiesService.showSwal(
                      'Partially Failed',
                      'You have some failed list which one have not been uploaded',
                      'warning',
                    );
                  } else {
                    this.utilitiesService.showSwal(
                      'Success',
                      'Data upload successfully done.',
                      'success',
                    );
                    this.dataSource = [];
                    // this.backToList();
                  }
                }
              } else {
                this.utilitiesService.showSwal(
                  'Uploaded!!',
                  'Data is uploading..... It will take some time. Please wait and check the list',
                  'success',
                );
                this.dataSource = [];
                this.backToList();
              }
            },
            (err) => {
              console.log(err);
              const subString = 'Requested row is duplicate ';
              if (err.error.message.includes(subString)) {
                // 👉️ substring is contained in string

                const jsonString = JSON.parse(
                  err.error.message.split(subString)[1],
                );
                console.log(jsonString);
                this.utilitiesService.showSwal(
                  'Bad Request!!',
                  subString + 'For pin: ' + jsonString.pin,
                  'error',
                );
              } else {
                this.utilitiesService.showSwal(
                  'Bad Request!!',
                  err.error.message,
                  'error',
                );
              }
            },
          );
      }
    }
  }

  stringDivider(str, width, spaceReplacer) {
    if (str.length > width) {
      let p = width;
      for (; p > 0 && str[p] != ' '; p--) {}
      if (p > 0) {
        const left = str.substring(0, p);
        const right = str.substring(p + 1);
        return (
          left + spaceReplacer + this.stringDivider(right, width, spaceReplacer)
        );
      }
    }
    return str;
  }

  makeColumn() {
    this.columns = []; // Make Column for Grid
    if (this.dataSource.length != 0) {
      // To find index which one have Max proparties because of if 1st row is blank then object missmatch
      let maxIndex = 0; // Initialize the index of the object with maximum properties
      let maxProperties = Object.keys(this.dataSource[0]).length;
      for (let i = 1; i < this.dataSource.length; i++) {
        const numProperties = Object.keys(this.dataSource[i]).length;
        if (numProperties > maxProperties) {
          maxProperties = numProperties;
          maxIndex = i;
        }
      }

      const key = Object.keys(this.dataSource[maxIndex]);
      key.forEach((element) => {
        let obj = {
          dataField: element,
          caption: element,
        };
        if (element === 'slNo' || element === 'sl') {
          this.columns.unshift(obj);
        } else {
          this.columns.push(obj);
        }
      });
    }
    console.log('columns: ', this.columns);
  }

  backToList() {
    this.showUploaderClick.emit(false);
  }

  public async downloadTemplate() {
    if (this.downloadCSVTemplateLink) {
      const response = await this.utilitiesService.showSwalAndReturn({
        title: 'Download!',
        message:
          'This CSV template might contain a few rows of example data that are provided to guide users to enter data. Please remove those example data before entering data in the template file.',
        type: 'info',
        confirmButtonText: 'Download',
        cancelButtonText: 'Cancel',
      });
      if (response.isConfirmed) {
        window.open(this.downloadCSVTemplateLink, '_blank');
      }
    } else {
      this.utilitiesService.showSwal(
        'Warning!',
        'No Download file found.',
        'warning',
      );
    }
  }

  private setContactPrefixForUserUpload() {
    this.dataSource.map((x) => {
      x.contact_number = x?.contact_number
        ? '0' + x?.contact_number.toString()
        : '';
    });
  }

  onRowPrepared(e) {
    if (e.rowType == 'data' && e.key.reason != null) {
      e.rowElement.style.background = '#f44336';
      e.rowElement.style.color = 'white';
    }
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('sheet1');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.csv.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          this.failedListFound
            ? 'Failed_' + this.title + '.csv'
            : this.title + '.csv',
        );
      });
    });
    e.cancel = true;
  }
}
