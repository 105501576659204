import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Url } from '../../enum/url.enum';
import { HttpRequestService } from '../http-request/http-request.service';
import { UtilitiesService } from '../utilities/utilities.service';
@Injectable({
  providedIn: 'root',
})
export class ActivityConfigurationService {
  activityAPI = Url.CONFIGURATION + '/activity/';
  subCategoryAPI = Url.CONFIGURATION + '/program-sub-category/';
  activityTargetUnitAPI = Url.CONFIGURATION + '/activity-target-unit/';
  activityTargetAPI = Url.CONFIGURATION + '/activity-target/';
  public selected: string;

  constructor(
    private readonly httpRequestService: HttpRequestService,
    private readonly utilitiesService: UtilitiesService,
  ) {}

  public getActivityTree(programId: string): Observable<any> {
    return this.httpRequestService
      .get(this.subCategoryAPI + 'all/' + programId)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getActivityList(): Observable<any> {
    return this.httpRequestService.get(this.activityAPI + 'all').pipe(
      map((x: any) => this.utilitiesService.responseHandler(x)),
      map((x: any) =>
        x.sort(this.utilitiesService.dynamicSortWithDate('created_at', 'desc')),
      ),
      map((x: any) => this.utilitiesService.getSlNo(x)),
      map((x: any) =>
        this.utilitiesService.addCustomProperty(x, 'GeneralList'),
      ),
    );
  }

  public getActivityDetails(
    program_sub_id: string,
    id: string,
  ): Observable<any> {
    return this.httpRequestService
      .get(
        this.activityAPI +
          'details/?program_sub_id=' +
          program_sub_id +
          '&id=' +
          id,
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public createCategory_Sub(body: any): Observable<any> {
    return this.httpRequestService
      .post(this.subCategoryAPI + 'create', body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public createActivity(body: any): Observable<any> {
    return this.httpRequestService
      .post(this.activityAPI + 'create', body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public updateCategory_Sub(
    program_id: string,
    id: string,
    body: any,
  ): Observable<any> {
    return this.httpRequestService
      .patch(
        this.subCategoryAPI + 'update/?program_id=' + program_id + '&id=' + id,
        body,
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public updateActivity(
    program_sub_id: string,
    id: string,
    body: any,
  ): Observable<any> {
    return this.httpRequestService
      .patch(
        this.activityAPI +
          'update/?program_sub_id=' +
          program_sub_id +
          '&id=' +
          id,
        body,
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public deleteCategory_Sub(program_id: string, id: string): Observable<any> {
    return this.httpRequestService
      .delete(
        this.subCategoryAPI + 'delete/?program_id=' + program_id + '&id=' + id,
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public deleteActivity(program_sub_id: string, id: string): Observable<any> {
    return this.httpRequestService
      .delete(
        this.activityAPI +
          'delete/?program_sub_id=' +
          program_sub_id +
          '&id=' +
          id,
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getActivityTargetUnitList(): Observable<any> {
    return this.httpRequestService
      .get(this.activityTargetUnitAPI)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public createActivityTargetUnit(body: any): Observable<any> {
    return this.httpRequestService
      .post(this.activityTargetUnitAPI, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getActivityTargetUnitListByfilter(
    project_id: string,
    activity_id: string,
    year: string,
  ): Observable<any> {
    return this.httpRequestService
      .get(
        this.activityTargetAPI +
          '/filtered-unit?project_id=' +
          project_id +
          '&activity_id=' +
          activity_id +
          '&year=' +
          year,
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getActivityDetailsById(id: string): Observable<any> {
    return this.httpRequestService
      .get(this.activityAPI + 'details-by-id/' + id)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
}
