<div class="container-scroller">
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div
      class="content-wrapper d-flex align-items-center text-center error-page bg-primary"
    >
      <div class="row flex-grow">
        <div class="col-lg-7 mx-auto text-white">
          <div class="row align-items-center d-flex flex-row">
            <div class="col-lg-12 pr-lg-4">
              <h1 class="display-1 mb-0">404</h1>
            </div>
            <div class="col-lg-12 error-page-divider pl-lg-4">
              <h2>SORRY!</h2>
              <h3 class="font-weight-light">
                The page you’re looking for was not found.
              </h3>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-12 text-center mt-xl-2">
              <a
                class="text-white font-weight-medium"
                [routerLink]="['/dashboard']"
                >Back to home</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- content-wrapper ends -->
  </div>
  <!-- page-body-wrapper ends -->
</div>
