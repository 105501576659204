<div class="row">
  <div class="col-sm-12">
    <label class="col-form-label" [ngClass]="{'requiredMark': validators.length > 0}">Select Activity Type:</label>
    <div>
      <!-- <dx-select-box [dataSource]="activityTagList" valueExpr="id" displayExpr="activity_type_name"
        [searchEnabled]="true" [searchExpr]="['activity_type_name']" [showClearButton]="true" [(value)]="value"
        (onSelectionChanged)="valueChanged($event)">
        <dx-validator [validationGroup]="validationGroupName" [validationRules]="validators"></dx-validator>

      </dx-select-box> -->
      <!-- valueExpr="id" -->
      <dx-tag-box 
      [items]="activityTagList"
      displayExpr="activity_type_name"
      [showSelectionControls]="true"
      [showDropDownButton]="true"
      [maxDisplayedTags]="3"
      [searchEnabled]="true"
      (onValueChanged)="valueChanged($event)" 
      [(value)]="value" 
    >
    <dx-validator [validationGroup]="validationGroupName" [validationRules]="validators"></dx-validator>

    </dx-tag-box>
    </div>
  </div>
</div> 
 