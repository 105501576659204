import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ProjectService } from '../../services/project/project.service';
import { UtilitiesService } from '../../services/utilities/utilities.service';
import { ValidationRule } from 'devextreme/common';
import { ActivityConfigurationService } from '../../services/activity-configuration/activity-configuration.service';
import { DxTreeViewComponent } from 'devextreme-angular';
import { ProjectActivityMappingService } from '../../services/project-activity-mapping/project-activity-mapping.service';
import { ReportService } from '../../services/report/report.service';

@Component({
  selector: 'app-activity-tree-view',
  templateUrl: './activity-tree-view.component.html',
  styleUrls: ['./activity-tree-view.component.css'],
})
export class ActivityTreeViewComponent implements OnInit, OnChanges {
  @Input() isEditing = false;

  @Input() text: string;

  @Input() validators: ValidationRule[];

  @Input() validationGroupName: string;

  @Input() value!: string;

  @Input() projectId: string;

  @Input() selectNodesRecursive: boolean = true;

  @Output() valueChange = new EventEmitter<string>();

  treeBoxValue: string[] = [];

  isTreeBoxOpened: boolean;
  @ViewChild(DxTreeViewComponent, { static: false }) treeView;

  activityTree: any[] = [];
  activityTreeFiltered: any[] = [];
  activityListByProject: any[];

  selectedModel = {
    selectedCategory: [],
    selectedActivity: [],
    selectedCategorySubCategory: [],
    selectedCategoryActivity: [],
  };
  flatData: any[] = [];
  constructor(
    public projectService: ProjectService,
    public utilitiesService: UtilitiesService,
    public activityConfigurationService: ActivityConfigurationService,
    private ref: ChangeDetectorRef,
    public projectActivityMappingService: ProjectActivityMappingService,
    public reportService: ReportService,
  ) {}

  ngOnInit() {
    this.getActivityTree();
  }

  ngOnChanges() {
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES **************/
    this.activityTreeFiltered = [];
    this.flatData = [];
    if (this.treeBoxValue?.length) {
      this.treeBoxValue = [];
      if (this.treeView.instance) this.treeView.instance.unselectAll();
    }
    if (this.projectId) {
      this.getActivityListByProject(this.projectId);
    }
  }

  private valueChanged(e): void {
    this.valueChange.emit(e);
  }

  private onOptionChanged(e): void {}

  private getActivityTree(): void {
    this.activityConfigurationService
      .getActivityTree(this.utilitiesService.programId)
      .subscribe((result) => {
        this.activityTree = result;
      });
  }

  private getActivityListByProject(projectId: string): void {
    this.activityListByProject = [];
    this.projectActivityMappingService
      .getActivityMappingListByProject(projectId)
      .subscribe((result) => {
        this.activityListByProject = this.utilitiesService.getFiltered(
          result,
          'is_active',
          true,
        );
        this.activityListByProject = this.activityListByProject.sort(
          this.utilitiesService.dynamicSort('activity_name'),
        );
        this.activityTreeFiltered = this.filterActivities(
          this.activityTree,
          this.activityListByProject,
        );
        this.flatData = [];
        this.flatActivities(this.activityTreeFiltered);
      });
  }

  // Function to filter activities from dataset1 based on dataset2
  private filterActivities(data1, data2): any {
    return data1.reduce((result, item) => {
      if (item.type.toLowerCase() === 'activity') {
        // Check if the activity is present in dataset2
        const isInDataset2 = data2.some(
          (activity) =>
            activity.activity_id === item.id &&
            activity.activity_name === item.name,
        );
        if (isInDataset2) {
          result.push(item);
        }
      } else if (item.items) {
        // Recursively process nested items
        const filteredItems = this.filterActivities(item.items, data2);
        if (filteredItems.length > 0) {
          result.push({
            ...item,
            items: filteredItems,
          });
        }
      }
      return result;
    }, []);
  }

  private flatActivities(data1): any {
    data1.forEach((item) => {
      if (item.items) {
        this.flatActivities(item.items);
        // delete item.items;
      }
      if (item.type.toLowerCase() === 'activity') {
        item.parent_id = item.program_sub_id;
      }
      this.flatData.push(item);
    });
  }

  public syncTreeViewSelection(e): void {
    this.updateSelection(this.treeView && this.treeView.instance);
  }

  public treeViewContentReady(e): void {
    this.updateSelection(e.component);
  }

  private updateSelection(treeView): void {
    if (!treeView) return;

    if (!this.treeBoxValue) {
      treeView.unselectAll();
      this.selectedModel.selectedCategory = [];
      this.selectedModel.selectedActivity = [];
      this.selectedModel.selectedCategoryActivity = [];
      this.selectedModel.selectedCategorySubCategory = [];
      this.valueChanged(this.selectedModel);
    }

    if (this.treeBoxValue) {
      this.treeBoxValue.forEach((value) => {
        treeView.selectItem(value);
      });
    }
  }

  private clickActivity(selectedActivities): void {
    this.selectedModel.selectedCategory = [];
    this.selectedModel.selectedActivity = [];
    this.selectedModel.selectedCategoryActivity = [];
    this.selectedModel.selectedCategorySubCategory = [];

    if (selectedActivities.length > 0) {
      selectedActivities.forEach((activity) => {
        if (activity.type == 'Category' || activity.type == 'Sub-Category') {
          if (activity.items) this.selectedCategoryActivity(activity.items);
          if (activity.items) this.selectedCategorySubCategory(activity.items);
          let obj = {
            id: activity.id,
            name: activity.name,
          };
          this.selectedModel.selectedCategory.push(obj);
        }
        if (activity.type == 'Activity') {
          let obj = {
            id: activity.id,
            name: activity.name,
          };
          this.selectedModel.selectedActivity.push(obj);
        }
      });

      // Extract the ids from objects in selectedActivity and selectedCategoryActivity
      const selectedCategoryActivityIds =
        this.selectedModel.selectedCategoryActivity.map(
          (activity) => activity.id,
        );

      // Filter out objects from selectedActivity based on selectedCategoryActivity
      const filteredActivity = this.selectedModel.selectedActivity.filter(
        (activity) => !selectedCategoryActivityIds.includes(activity.id),
      );

      // Extract the ids from objects in selectedActivity and selectedCategoryActivity
      const selectedCategorySubCategoryIds =
        this.selectedModel.selectedCategorySubCategory.map(
          (activity) => activity.id,
        );

      // Filter out objects from selectedCategory based on selectedCategorySubcategory
      const filteredCategory = this.selectedModel.selectedCategory.filter(
        (activity) => !selectedCategorySubCategoryIds.includes(activity.id),
      );

      this.selectedModel.selectedActivity = filteredActivity;
      this.selectedModel.selectedCategory = filteredCategory;

      this.selectedModel.selectedCategory = this.removeDuplicate(
        this.selectedModel.selectedCategory,
      );
      this.selectedModel.selectedActivity = this.removeDuplicate(
        this.selectedModel.selectedActivity,
      );
      this.selectedModel.selectedCategorySubCategory = this.removeDuplicate(
        this.selectedModel.selectedCategorySubCategory,
      );
      this.selectedModel.selectedCategoryActivity = this.removeDuplicate(
        this.selectedModel.selectedCategoryActivity,
      );
      this.valueChanged(this.selectedModel);
    }
  }

  private removeDuplicate(array): any[] {
    return array.filter(
      (value, index, self) =>
        index === self.findIndex((obj) => obj.id === value.id),
    );
  }

  // this function generate those activity which category is selected already.
  private selectedCategoryActivity(categories): void {
    if (categories.length > 0) {
      categories.forEach((item) => {
        if (item.type == 'Activity') {
          let obj = {
            id: item.id,
            name: item.name,
          };
          this.selectedModel.selectedCategoryActivity.push(obj);
        } else {
          if (item.items) this.selectedCategoryActivity(item.items);
        }
      });
    }
  }

  private selectedCategorySubCategory(categories): void {
    if (categories.length > 0) {
      categories.forEach((item) => {
        if (item.type == 'Sub-Category') {
          let obj = {
            id: item.id,
            name: item.name,
          };
          this.selectedModel.selectedCategorySubCategory.push(obj);
        } else {
          if (item.items) this.selectedCategorySubCategory(item.items);
        }
      });
    }
  }

  public treeViewSelectionChanged(e): void {
    this.syncSelection(e.component);
    this.treeBoxValue = e.component.getSelectedNodeKeys();
  }

  private syncSelection(treeView): void {
    const selectedActivities = treeView
      .getSelectedNodes()
      .map((node) => node.itemData);
    this.clickActivity(selectedActivities);
  }
}
