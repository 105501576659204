import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { AreaFilterTypeEnum } from '../../enum/area-filter-type.enum';
import { WorkingAreaTypeEnum } from '../../enum/staff-mapping/working-area.enum';
import { Url } from '../../enum/url.enum';
import {
  IAddStaffMapping,
  IAddStaffMappingList,
} from '../../interface/staff-mapping/staff-mapping.interface';
import { HttpRequestService } from '../http-request/http-request.service';
import { UtilitiesService } from '../utilities/utilities.service';

@Injectable({
  providedIn: 'root',
})
export class StaffMappingService {
  userAPI = Url.AUTH + '/staff-mapping';
  private isValidPinTriggerObs$: BehaviorSubject<any> = new BehaviorSubject(
    false,
  );

  constructor(
    private readonly httpService: HttpRequestService,
    private readonly utilitiesService: UtilitiesService,
  ) {}

  // public getAllWorkingAreaByUserId(
  //   cohortId: any,
  //   userId: any
  // ): Observable<any> {
  //   return this.httpService
  //     .get(
  //       this.userAPI +
  //         '/user-mapping/' +
  //         cohortId +
  //         '/allWorkingAreaByUser/'
  //         userId
  //     )
  //     .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  // }

  public getAllMappedStaff(): Observable<any> {
    return this.httpService
      .get(this.userAPI + '/all')
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getVerifiedByAndApprovedByStaffs(
    projectId: string,
    roleId: string,
    userFilterType: 'APPROVER' | 'VERIFIER',
  ): Observable<any> {
    return this.httpService
      .get(
        Url.AUTH +
          '/staff-mapping/assign-to-users?project_id=' +
          projectId +
          '&role_id=' +
          roleId +
          '&user_filter_type=' +
          userFilterType,
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getStaffMappingList(projectId: string): Observable<any> {
    return this.httpService
      .get(this.userAPI + '/details/project/' + projectId)
      .pipe(
        map((x: any) => this.utilitiesService.responseHandler(x)),
        map((x: any) => this.utilitiesService.getSlNo(x)),
      );
  }

  public getProjectAreaWiseStaff(params: {
    project_id: string;
    role_id?: string;
    filter_type?: WorkingAreaTypeEnum;
    filter_values?: string;
    is_only_type?: boolean;
  }): Observable<any> {
    let url =
      this.userAPI +
      '/project-area-wise-staffs?project_id=' +
      params.project_id;
    if (params?.role_id) {
      url += '&role_id=' + params.role_id;
    }
    if (params?.filter_type && params?.filter_values) {
      url +=
        '&filter_type=' +
        params.filter_type +
        '&filter_values=' +
        params.filter_values;
    }
    if (params?.is_only_type) {
      url += '&is_only_type=' + params.is_only_type;
    }

    return this.httpService
      .get(url)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public createStaffMapping(body: IAddStaffMappingList): Observable<any> {
    return this.httpService
      .post(this.userAPI + '/create', body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public updateStaffMapping(
    workingAreaType: string,
    body: IAddStaffMapping,
  ): Observable<any> {
    return this.httpService
      .put(this.userAPI + '/update/working-area-type/' + workingAreaType, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getStaffMappingByUserId(
    userId: string,
    projectId: string,
    workingAreaType?: string,
  ): Observable<any> {
    let url =
      this.userAPI + '/details/user/' + userId + '/project/' + projectId;
    if (workingAreaType) {
      url += '?working_area_type=' + workingAreaType;
    }
    return this.httpService
      .get(url)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getSubordinateStaffs(
    projectId: string,
    userId: string,
  ): Observable<any> {
    return this.httpService
      .get(
        this.userAPI +
          '/subordinate-users?project_id=' +
          projectId +
          '&user_id=' +
          userId,
      )
      .pipe(
        map((x: any) => this.utilitiesService.responseHandler(x)),
        map((x: any) =>
          this.utilitiesService.addCustomProperty(x, 'SubordinateStaffs'),
        ),
        map((x: any) =>
          Array.from(new Map(x.map((item) => [item.pin, item])).values()),
        ),
      );
  }

  public getReportingSubordinateUsers(
    projectId: string,
    subordinateUserFilterType: AreaFilterTypeEnum,
    reporterUserFilterValues: any[] = null,
  ): Observable<any> {
    if (subordinateUserFilterType != null) {
      return this.httpService
        .get(
          this.userAPI +
            '/reporting-subordinate-users?project_id=' +
            projectId +
            '&subordinate_user_filter_type=' +
            subordinateUserFilterType +
            '&reporter_user_filter_values=' +
            reporterUserFilterValues,
        )
        .pipe(
          map((x: any) => this.utilitiesService.responseHandler(x)),
          map((x: any) => {
            return x.map((obj) => {
              let area;
              // const object = {
              //   [AreaFilterTypeEnum.HEAD_OFFICE]: [],
              //   [AreaFilterTypeEnum.DIVISION]: ['division_name'],
              //   [AreaFilterTypeEnum.DISTRICT]: ['division_name', 'district_name']
              // }
              switch (subordinateUserFilterType) {
                case AreaFilterTypeEnum.HEAD_OFFICE:
                  area = '-';
                  break;
                case AreaFilterTypeEnum.DIVISION:
                  area = '-' + obj.division_name + '-';
                  break;
                case AreaFilterTypeEnum.DISTRICT:
                  // const keys = object[AreaFilterTypeEnum.DISTRICT];
                  // for (const key of keys) {
                  // }
                  area =
                    '-' + obj.division_name + '>' + obj.district_name + '-';
                  break;
                case AreaFilterTypeEnum.UPAZILA:
                  area =
                    '-' +
                    obj.division_name +
                    '>' +
                    obj.district_name +
                    '>' +
                    obj.sub_district_name +
                    '-';
                  break;
                case AreaFilterTypeEnum.UNION:
                  area =
                    '-' +
                    obj.division_name +
                    '>' +
                    obj.district_name +
                    '>' +
                    obj.sub_district_name +
                    '>' +
                    obj.union_name +
                    '-';
                  break;
                case AreaFilterTypeEnum.WARD:
                  area =
                    '-' +
                    obj.division_name +
                    '>' +
                    obj.district_name +
                    '>' +
                    obj.sub_district_name +
                    '>' +
                    obj.union_name +
                    '>' +
                    obj.ward_name +
                    '-';
                  break;
                default:
                  break;
              }
              return {
                ...obj,
                name_pin: obj.name + '-' + obj.role_short_form + area + obj.pin,
              };
            });
          }),
        );
    } else {
      return null;
    }
  }

  public deleteStaffMapping(
    userId: string,
    projectId: string,
    workingAreaType?: string,
  ): Observable<any> {
    return this.httpService
      .delete(
        this.userAPI +
          '/delete/user/' +
          userId +
          '/project/' +
          projectId +
          '/working-area-type/' +
          workingAreaType,
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public isValidPinObs(): Observable<any> {
    return this.isValidPinTriggerObs$.asObservable();
  }

  public isValidPinTriggerObs(data: any): void {
    this.isValidPinTriggerObs$.next(data);
  }
}
