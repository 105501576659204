<div class="content-wrapper">
  <div class="row">
    <div class="col-12 grid-margin stretch-card">
      <div class="card">
        <div class="row">
          <div class="col-12 col-xl-12 mb-4 mb-xl-0">
            <div class="card-body">
              <div style="display: flex">
                <div style="flex: 2">
                  <h2 class="card-title">{{ title }}</h2>
                </div>

                <div class="newButtonAlignrigt" style="flex: 1">
                  <div>
                    <span class="cursor" (click)="backToList()">
                      <img
                        style="width: 4%"
                        src="../../../../../assets/images/left-arrow-ash.png"
                        alt=""
                      />Back To List
                    </span>
                  </div>
                  <!-- <dx-button type="normal" text="Go back to list"  [routerLink]="['../asset']"></dx-button> -->
                </div>
              </div>
              <p class="card-description"></p>
              <div class="newButtonAlignrigt">
                <dx-button
                  class="themeButton mr-1"
                  (click)="downloadTemplate()"
                  text="Download .csv Template"
                ></dx-button>
              </div>

              <div
                class="container"
                appDnd
                (fileDropped)="onFileDropped($event)"
              >
                <input
                  type="file"
                  #fileDropRef
                  id="fileDropRef"
                  accept=".csv"
                  (change)="fileBrowseHandler($event)"
                />
                <svg style="width: 15px; margin-right: 5px" viewBox="0 0 63 64">
                  <g fill="#3B454F" fill-rule="nonzero">
                    <path
                      d="M42.656 15.135a1.953 1.953 0 0 1-1.391-.578L31.5 4.795l-9.765 9.762a1.97 1.97 0 1 1-2.785-2.785L30.106.616a1.97 1.97 0 0 1 2.785 0l11.157 11.156a1.97 1.97 0 0 1-1.392 3.363z"
                    />
                    <path
                      d="M31.5 36.791a1.97 1.97 0 0 1-1.969-1.969V2.01a1.97 1.97 0 0 1 3.938 0v32.812a1.97 1.97 0 0 1-1.969 1.969z"
                    />
                    <path
                      d="M55.781 63.041H7.22A7.225 7.225 0 0 1 0 55.822V41.385a4.599 4.599 0 0 1 4.594-4.594h7.234a4.567 4.567 0 0 1 4.402 3.276l2.814 9.382a.658.658 0 0 0 .628.467h23.656a.658.658 0 0 0 .628-.467l2.814-9.385a4.572 4.572 0 0 1 4.402-3.273h7.234A4.599 4.599 0 0 1 63 41.385v14.437a7.225 7.225 0 0 1-7.219 7.219zM4.594 40.729a.656.656 0 0 0-.657.656v14.437a3.286 3.286 0 0 0 3.282 3.282H55.78a3.286 3.286 0 0 0 3.282-3.282V41.385a.656.656 0 0 0-.657-.656h-7.234a.65.65 0 0 0-.628.467L47.73 50.58a4.628 4.628 0 0 1-4.402 3.274H19.672a4.567 4.567 0 0 1-4.402-3.276l-2.814-9.382a.65.65 0 0 0-.628-.467H4.594z"
                    />
                  </g>
                </svg>
                <span>Click or Drag and Drop file here to upload</span>
              </div>

              <div class="csvPreviewText">
                {{ failedListFound ? "Failed List" : "CSV File Preview" }}
              </div>
              <dx-data-grid
                #gridContainer
                [dataSource]="dataSource"
                [showBorders]="true"
                [showRowLines]="true"
                [allowColumnResizing]="true"
                columnResizingMode="widget"
                [focusedRowEnabled]="false"
                [rowAlternationEnabled]="false"
                [wordWrapEnabled]="true"
                [columns]="columns"
                [columnAutoWidth]="true"
                (onRowPrepared)="onRowPrepared($event)"
                (onExporting)="onExporting($event)"
              >
                <dxo-sorting mode="multiple"></dxo-sorting>
                <dxo-export
                  [enabled]="dataSource.length > 0 ? true : false"
                  [fileName]="failedListFound ? 'Failed_' + title : title"
                  [printingEnabled]="false"
                  [formats]="['csv']"
                ></dxo-export>
                <dxo-paging [pageSize]="utilitiesService.rowSize"></dxo-paging>
                <dxo-search-panel
                  [visible]="true"
                  [width]="240"
                  placeholder="Search..."
                >
                </dxo-search-panel>
                <!-- <dxo-filter-row [visible]="true"></dxo-filter-row> -->
                <dxo-header-filter
                  [visible]="true"
                  [allowSearch]="true"
                ></dxo-header-filter>
                <dxo-scrolling
                  [scrollByContent]="true"
                  columnRenderingMode="virtual"
                  showScrollbar="always"
                ></dxo-scrolling>
                <dxo-pager
                  [visible]="dataSource.length > 0 ? true : false"
                  [showPageSizeSelector]="true"
                  [allowedPageSizes]="utilitiesService.allowedPageSizes"
                  [showInfo]="true"
                >
                </dxo-pager>
              </dx-data-grid>
              <div class="center" style="margin-top: 5%">
                <dx-button
                  class="themeButton"
                  [text]="'Upload'"
                  (onClick)="submitFile($event)"
                ></dx-button>
                <!-- [disabled]="dataSource.length == 0 || failedListFound" -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
