import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BulkUploadComponent } from '../components/bulk-upload/bulk-upload.component';
import { PulseLoadingComponent } from '../components/pulse-loading/pulse-loading.component';
import { FooterComponent } from './../components/footer/footer.component';
import { DevExtremeModule } from './devExtreme.module';
import { ProgrammeComponent } from '../components/programme/programme.component';
import { ProjectComponent } from '../components/project/project.component';
import { MonthYearComponent } from '../components/month-year/month-year.component';
import { ActivityTreeViewComponent } from '../components/activity-tree-view/activity-tree-view.component';
import { ActivityTypeListComponent } from '../components/activity-type-list/activity-type-list.component';
import { AreaComponent } from '../components/area/area.component';
// import { NullWithDefaultPipe } from '../pipe/null-with-default.pipe';

@NgModule({
  imports: [DevExtremeModule, RouterModule, CommonModule],
  declarations: [
    FooterComponent,
    PulseLoadingComponent,
    BulkUploadComponent,
    ProgrammeComponent,
    ProjectComponent,
    MonthYearComponent,
    ActivityTreeViewComponent,
    ActivityTypeListComponent,
    AreaComponent
    // NullWithDefaultPipe
  ],
  exports: [
    FooterComponent,
    PulseLoadingComponent,
    BulkUploadComponent,
    ProgrammeComponent,
    ProjectComponent,
    MonthYearComponent,
    ActivityTreeViewComponent,
    ActivityTypeListComponent,
    AreaComponent
  ],
})
export class SharedModule {}
