import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { UtilitiesService } from '../../services/utilities/utilities.service';
import { ValidationRule } from 'devextreme/common';
import ValidationEngine from 'devextreme/ui/validation_engine';
import { AreaFilterTypeEnum } from '../../enum/area-filter-type.enum';
import { GeoManagementService } from '../../services/geo-management/geo-management.service';
import { AreaModel } from '../../interface/geo-location-management/geo-location-management.interface';
import { ProjectOfficeService } from '../../services/project-office/project-office.service';
import { ProjectComponent } from '../project/project.component';
import { ReportService } from 'src/app/shared/services/report/report.service';
import { StaffMappingService } from '../../services/staff-mapping/staff-mapping.service';
import { RoleHierarchyService } from '../../services/role-hierarchy/role-hierarchy.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.css'],
})
export class AreaComponent implements OnInit, OnChanges {
  @Input() isEditing = false;

  @Input() areaTypeLevel: string;

  @Input() filterStaff: boolean;

  @Input() validators: ValidationRule[];

  @Input() areaValidationGroupName: string;

  @Output() valueChange = new EventEmitter<any>();

  @Input() projectId: string;

  @Input() isWorkingTypeFilter: boolean = false;

  @Input() isWardShow: boolean = false;

  @ViewChild(ProjectComponent, { static: false })
  projectComponent: ProjectComponent;

  divisionList: any[];
  districtList: any[];
  subDistrictList: any[];
  unionList: any[];
  wardList: any[];
  areaModel: AreaModel = new AreaModel();
  divisionLoader: boolean;
  districtLoader: boolean;
  subdistrictLoader: boolean;
  unionLoader: boolean;
  wardLoader: boolean;
  selectedDIVISION: any[] = [];
  selectedDISTRICT: any[] = [];
  selectedSUB_DISTRICT: any[] = [];
  selectedUNION: any[] = [];
  selectedWARD: any[] = [];

  headofficeStaffList: any[];
  divisionStaffList: any[];
  districtStaffList: any[];
  sub_districtStaffList: any[];
  upazilaStaffList: any[] = [];
  unionStaffList: any[];
  wardStaffList: any[];

  selectedHeadofficeStaff: any[] = [];
  selectedDIVISIONStaff: any[] = [];
  selectedDISTRICTStaff: any[] = [];
  selectedSUB_DISTRICTStaff: any[] = [];
  selectedUNIONStaff: any[] = [];
  selectedWARDStaff: any[] = [];
  workingAreaTypes: any;
  workingAreaTypesAndStaffs: any[] = [];
  areaFilterTypeEnum = AreaFilterTypeEnum;

  constructor(
    public reportService: ReportService,
    public geoLocationService: GeoManagementService,
    public utilitiesService: UtilitiesService,
    public staffMappingService: StaffMappingService,
    public projectOfficeService: ProjectOfficeService,
    public roleHierarchyService: RoleHierarchyService,
  ) {}

  ngOnInit() {
    //this.getDivisionList();
  }

  ngOnChanges() {
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES **************/
    if (this.projectId) {
      if (this.isWorkingTypeFilter) {
        this.getDivisionList(this.projectId);
        this.getAreaTypeByProject(this.projectId);
      } else {
        this.getDivisionList(this.projectId);
        ValidationEngine.resetGroup(this.areaValidationGroupName);
      }
    } else {
      this.workingAreaTypes = [];
      this.divisionList = [];
      ValidationEngine.resetGroup(this.areaValidationGroupName);
    }

    this.selectedDIVISION = [];
    this.selectedDISTRICT = [];
    this.selectedSUB_DISTRICT = [];
    this.selectedUNION = [];
    this.selectedWARD = [];

    this.selectedHeadofficeStaff = [];
    this.selectedDIVISIONStaff = [];
    this.selectedDISTRICTStaff = [];
    this.selectedSUB_DISTRICTStaff = [];
    this.selectedUNIONStaff = [];
    this.selectedWARDStaff = [];
  }

  private filterNamesByIds(array: any[], ids: any[]): any[] {
    return array
      ?.filter((staff) => ids.includes(staff.user_id))
      .map((staff) => staff.name);
  }

  valueChanged(
    area: string = null,
    areaIds: { id: string; name: string }[] = null,
  ) {
    area =
      this.areaModel.ward.length > 0
        ? AreaFilterTypeEnum.WARD
        : this.areaModel.union.length > 0
          ? AreaFilterTypeEnum.UNION
          : this.areaModel.sub_district.length > 0
            ? AreaFilterTypeEnum.SUB_DISTRICT
            : this.areaModel.district.length > 0
              ? AreaFilterTypeEnum.DISTRICT
              : AreaFilterTypeEnum.DIVISION;
    this.emitAreaModel(area);
  }

  private getDivisionList(projectId) {
    this.divisionLoader = true;
    this.divisionList = [];
    this.projectOfficeService
      .getProjectOfficeAreaList(projectId, AreaFilterTypeEnum.DIVISION)
      .subscribe(
        (result) => {
          this.divisionLoader = false;
          this.divisionList = this.utilitiesService.getFiltered(
            result,
            'is_active',
            true,
          );
        },
        (err) => {
          this.divisionLoader = false;
        },
      );
  }

  public divisionOptionChange(e) {
    this.resetArrayUpTo(AreaFilterTypeEnum.DIVISION);
    if (e.value.length > 0) {
      e.value.forEach((element) => {
        if (element) {
          this.areaModel.division.push({
            id: element.division_id,
            name: element.division_name,
          });

          let index = this.selectedDIVISION.findIndex(
            (s) => s == element.division_id,
          );
          if (index <= -1) {
            this.selectedDIVISION.push(element.division_id);
          }
        }
      });
      if (this.areaModel.division.length != 0) {
        this.getDistrictList(
          this.projectId,
          this.areaModel.division.map((obj) => obj.id).toString(),
        );
      }
      this.valueChanged(AreaFilterTypeEnum.DIVISION, this.areaModel.division);
    } else if (e.value.length == 0 && e.previousValue.length > 0) {
      this.valueChange.emit(new AreaModel());
    }
  }

  private getDistrictList(projectId: string, divisionId: string) {
    this.districtList = [];
    this.districtLoader = true;
    this.projectOfficeService
      .getProjectOfficeAreaList(
        projectId,
        AreaFilterTypeEnum.DISTRICT,
        divisionId,
      )
      .subscribe(
        (result) => {
          this.districtLoader = false;
          this.districtList = this.utilitiesService.getFiltered(
            result,
            'is_active',
            true,
          );
          this.districtList = this.districtList.sort(
            this.utilitiesService.dynamicSort('district_name'),
          );
        },
        (err) => {
          this.districtLoader = false;
        },
      );
  }

  public districtOptionChange(e) {
    this.resetArrayUpTo(AreaFilterTypeEnum.DISTRICT);
    if (e.value.length > 0) {
      e.value.forEach((element) => {
        if (element) {
          this.areaModel.district.push({
            id: element.district_id,
            name: element.district_name,
          });

          let index = this.selectedDISTRICT.findIndex(
            (s) => s == element.district_id,
          );
          if (index <= -1) {
            this.selectedDISTRICT.push(element.district_id);
          }
        }
      });
      if (this.areaModel.district.length != 0) {
        this.getSubDistrictList(
          this.projectId,
          this.areaModel.district.map((obj) => obj.id).toString(),
        );
      }
      this.valueChanged(AreaFilterTypeEnum.DISTRICT, this.areaModel.district);
    } else if (e.value.length == 0 && e.previousValue.length > 0) {
      this.valueChanged(AreaFilterTypeEnum.DIVISION, this.areaModel.division);
    }
  }

  private getSubDistrictList(projectId: string, districtId: string) {
    this.subDistrictList = [];
    this.subdistrictLoader = true;
    this.projectOfficeService
      .getProjectOfficeAreaList(
        projectId,
        AreaFilterTypeEnum.SUB_DISTRICT,
        districtId,
      )
      .subscribe(
        (result) => {
          this.subdistrictLoader = false;
          this.subDistrictList = this.utilitiesService.getFiltered(
            result,
            'is_active',
            true,
          );
          this.subDistrictList = this.subDistrictList.sort(
            this.utilitiesService.dynamicSort('sub_district_name'),
          );
        },
        (err) => {
          this.subdistrictLoader = false;
        },
      );
  }

  public subDistrictOptionChange(e) {
    this.resetArrayUpTo(AreaFilterTypeEnum.SUB_DISTRICT);
    if (e.value.length > 0) {
      e.value.forEach((element) => {
        if (element) {
          this.areaModel.sub_district.push({
            id: element.sub_district_id,
            name: element.sub_district_name,
          });

          let index = this.selectedSUB_DISTRICT.findIndex(
            (s) => s == element.sub_district_id,
          );
          if (index <= -1) {
            this.selectedSUB_DISTRICT.push(element.sub_district_id);
          }
        }
      });
      if (this.areaModel.sub_district.length != 0) {
        this.getUnionList(
          this.projectId,
          this.areaModel.sub_district.map((obj) => obj.id).toString(),
        );
      }
      this.valueChanged(
        AreaFilterTypeEnum.SUB_DISTRICT,
        this.areaModel.sub_district,
      );
    } else if (e.value.length == 0 && e.previousValue.length > 0) {
      this.valueChanged(AreaFilterTypeEnum.DISTRICT, this.areaModel.district);
    }
  }

  private getUnionList(projectId: string, subDistrictId: string) {
    this.unionList = [];
    this.unionLoader = true;
    this.projectOfficeService
      .getProjectOfficeAreaList(
        projectId,
        AreaFilterTypeEnum.UNION,
        subDistrictId,
      )
      .subscribe(
        (result) => {
          this.unionLoader = false;

          this.unionList = this.utilitiesService.getFiltered(
            result,
            'is_active',
            true,
          );
          this.unionList = this.unionList.sort(
            this.utilitiesService.dynamicSort('union_name'),
          );
        },
        (err) => {
          this.unionLoader = false;
        },
      );
  }

  public unionOptionChange(e) {
    this.resetArrayUpTo(AreaFilterTypeEnum.UNION);
    if (e.value.length > 0) {
      e.value.forEach((element) => {
        if (element) {
          this.areaModel.union.push({
            id: element.union_id,
            name: element.union_name,
          });

          let index = this.selectedUNION.findIndex(
            (s) => s == element.union_id,
          );
          if (index <= -1) {
            this.selectedUNION.push(element.union_id);
          }
        }
      });
      if (this.areaModel.union.length != 0) {
        this.getWardList(
          this.projectId,
          this.areaModel.union.map((obj) => obj.id).toString(),
        );
      }
      this.valueChanged(AreaFilterTypeEnum.UNION, this.areaModel.union);
    } else if (e.value.length == 0 && e.previousValue.length > 0) {
      this.valueChanged(
        AreaFilterTypeEnum.SUB_DISTRICT,
        this.areaModel.sub_district,
      );
    }
  }

  private getWardList(projectId: string, unionId: string) {
    this.wardList = [];
    this.wardLoader = true;
    this.projectOfficeService
      .getProjectOfficeAreaList(projectId, AreaFilterTypeEnum.WARD, unionId)
      .subscribe(
        (result) => {
          this.wardLoader = false;
          this.wardList = this.utilitiesService.getFiltered(
            result,
            'is_active',
            true,
          );
          this.wardList = this.wardList.sort(
            this.utilitiesService.dynamicSort('ward_name'),
          );
        },
        (err) => {
          this.wardLoader = false;
        },
      );
  }

  public wardOptionChange(e) {
    this.resetArrayUpTo(AreaFilterTypeEnum.WARD);
    if (e.value.length > 0) {
      e.value.forEach((element) => {
        if (element) {
          this.areaModel.ward.push({
            id: element.ward_id,
            name: element.ward_name,
          });

          let index = this.selectedWARD.findIndex((s) => s == element.ward_id);
          if (index <= -1) {
            this.selectedWARD.push(element.ward_id);
          }
        }
      });
      this.valueChanged(AreaFilterTypeEnum.WARD, this.areaModel.ward);
    } else if (e.value.length == 0 && e.previousValue.length > 0) {
      this.valueChanged(AreaFilterTypeEnum.WARD, this.areaModel.ward);
    }
  }

  public staffChanged(e, area: AreaFilterTypeEnum): void {
    this.resetArrayUpTo(area);
    let index = this.workingAreaTypes.indexOf(area);
    this.workingAreaTypesAndStaffs[index].staff_ids = [];
    this.workingAreaTypesAndStaffs[index].staff_names = [];
    this.workingAreaTypesAndStaffs[index].area_filter_ids = [];
    this.workingAreaTypesAndStaffs[index].area_filter_names = [];
    if (e.value.length == 0 && e.previousValue.length > 0) {
      index = this.workingAreaTypes.indexOf(area) - 1;
      this.emitAreaModel(index.toString());
    }
    if (e.value.length > 0) {
      const values = [];
      e.value.forEach((itm) => {
        this.workingAreaTypesAndStaffs[index].staff_ids.push(itm.user_id);
        this.workingAreaTypesAndStaffs[index].staff_names.push(itm.name);
        switch (itm.working_area_type) {
          case AreaFilterTypeEnum.HEAD_OFFICE:
            this.workingAreaTypesAndStaffs[index].area_filter_ids = [];
            this.workingAreaTypesAndStaffs[index].area_filter_names = [];
            break;
          case AreaFilterTypeEnum.UPAZILA:
            this.workingAreaTypesAndStaffs[index].area_filter_ids.push(
              itm.sub_district_id,
            );
            this.workingAreaTypesAndStaffs[index].area_filter_names.push(
              itm.sub_district_name,
            );
            break;
          default:
            this.workingAreaTypesAndStaffs[index].area_filter_ids.push(
              itm[itm.working_area_type?.toLowerCase() + '_id'],
            );
            this.workingAreaTypesAndStaffs[index].area_filter_names.push(
              itm[itm.working_area_type?.toLowerCase() + '_name'],
            );
            break;
        }
        values.push(itm.user_id);
      });
      const areaValue =
        index != this.workingAreaTypes?.length - 1
          ? this.workingAreaTypes[index + 1]
          : null;
      if (areaValue != null) {
        this.getStaffsByProject(areaValue, values).subscribe((result) => {
          this[areaValue.toLowerCase().replace(/\s/g, '') + 'StaffList'] =
            result;

          if (
            result?.length > 0 &&
            index !== this.workingAreaTypes?.length - 1
          ) {
            this.workingAreaTypesAndStaffs[index].area_filter_ids = [];
            result.forEach((element) => {
              switch (area) {
                case AreaFilterTypeEnum.HEAD_OFFICE:
                  break;
                case AreaFilterTypeEnum.UPAZILA:
                  this.workingAreaTypesAndStaffs[index].area_filter_ids.push(
                    element.sub_district_id,
                  );
                  break;
                default:
                  this.workingAreaTypesAndStaffs[index].area_filter_ids.push(
                    element[area?.toLowerCase() + '_id'],
                  );
                  break;
              }
            });
          }
          this.emitAreaModel(index.toString());
          return;
        });
      } else {
        this.emitAreaModel(index.toString());
      }
    }
  }

  private async emitAreaModel(key: string = null): Promise<void> {
    if (this.filterStaff) {
      const index = parseInt(key);
      this.workingAreaTypesAndStaffs[index].area_filter_ids = [
        ...new Set(this.workingAreaTypesAndStaffs[index].area_filter_ids),
      ];
      this.valueChange.emit({
        index: index,
        model: this.workingAreaTypesAndStaffs,
      });
    } else {
      this.areaModel.filter_type = key;
      this.areaModel.filter_value = this.areaModel[key.toLowerCase()].map(
        (obj) => obj.id,
      );
      this.valueChange.emit(this.areaModel);
    }
  }

  private resetArrayUpTo(key: string): void {
    switch (key) {
      case AreaFilterTypeEnum.HEAD_OFFICE:
        this.divisionStaffList = [];
        this.districtStaffList = [];
        this.sub_districtStaffList = [];
        this.upazilaStaffList = [];
        this.unionStaffList = [];
        this.wardStaffList = [];

        this.selectedDIVISIONStaff = [];
        this.selectedDISTRICTStaff = [];
        this.selectedSUB_DISTRICTStaff = [];
        this.selectedUNIONStaff = [];
        this.selectedWARDStaff = [];
        break;
      case AreaFilterTypeEnum.DIVISION:
        this.areaModel.division = [];
        this.districtList = [];
        this.subDistrictList = [];
        this.unionList = [];
        this.wardList = [];

        this.selectedDISTRICT = [];
        this.selectedSUB_DISTRICT = [];
        this.selectedUNION = [];
        this.selectedWARD = [];

        this.districtStaffList = [];
        this.sub_districtStaffList = [];
        this.upazilaStaffList = [];
        this.unionStaffList = [];
        this.wardStaffList = [];

        this.selectedDISTRICTStaff = [];
        this.selectedSUB_DISTRICTStaff = [];
        this.selectedUNIONStaff = [];
        this.selectedWARDStaff = [];
        break;
      case AreaFilterTypeEnum.DISTRICT:
        this.areaModel.district = [];
        this.subDistrictList = [];
        this.unionList = [];
        this.wardList = [];

        this.selectedSUB_DISTRICT = [];
        this.selectedUNION = [];
        this.selectedWARD = [];

        this.sub_districtStaffList = [];
        this.upazilaStaffList = [];
        this.unionStaffList = [];
        this.wardStaffList = [];

        this.selectedSUB_DISTRICTStaff = [];
        this.selectedUNIONStaff = [];
        this.selectedWARDStaff = [];
        break;
      case AreaFilterTypeEnum.SUB_DISTRICT:
        this.areaModel.sub_district = [];
        this.unionList = [];
        this.wardList = [];

        this.selectedUNION = [];
        this.selectedWARD = [];

        this.unionStaffList = [];
        this.wardStaffList = [];

        this.selectedUNIONStaff = [];
        this.selectedWARDStaff = [];
        break;
      case AreaFilterTypeEnum.UPAZILA:
        this.unionStaffList = [];
        this.wardStaffList = [];

        this.selectedUNIONStaff = [];
        this.selectedWARDStaff = [];
        break;
      case AreaFilterTypeEnum.UNION:
        this.areaModel.union = [];
        this.areaModel.ward = [];
        this.wardList = [];
        this.selectedWARD = [];
        this.wardStaffList = [];
        this.selectedWARDStaff = [];
        break;
      case AreaFilterTypeEnum.WARD:
        this.areaModel.ward = [];
        break;
      default:
        break;
    }
  }

  public isAreaTypeShow(currentAreaType: string): boolean {
    if (currentAreaType == AreaFilterTypeEnum.WARD) {
      if (this.areaTypeLevel == AreaFilterTypeEnum.WARD) {
        return true;
      } else return false;
    }
    if (currentAreaType == AreaFilterTypeEnum.UNION) {
      if (
        this.areaTypeLevel == AreaFilterTypeEnum.WARD ||
        this.areaTypeLevel == AreaFilterTypeEnum.UNION
      ) {
        return true;
      } else return false;
    }

    if (currentAreaType == AreaFilterTypeEnum.SUB_DISTRICT) {
      if (
        this.areaTypeLevel == AreaFilterTypeEnum.WARD ||
        this.areaTypeLevel == AreaFilterTypeEnum.UNION ||
        this.areaTypeLevel == AreaFilterTypeEnum.SUB_DISTRICT
      ) {
        return true;
      } else return false;
    }

    if (currentAreaType == AreaFilterTypeEnum.DISTRICT) {
      if (
        this.areaTypeLevel == AreaFilterTypeEnum.WARD ||
        this.areaTypeLevel == AreaFilterTypeEnum.UNION ||
        this.areaTypeLevel == AreaFilterTypeEnum.SUB_DISTRICT ||
        this.areaTypeLevel == AreaFilterTypeEnum.DISTRICT
      ) {
        return true;
      } else return false;
    }

    if (currentAreaType == AreaFilterTypeEnum.DIVISION) {
      if (
        this.areaTypeLevel == AreaFilterTypeEnum.WARD ||
        this.areaTypeLevel == AreaFilterTypeEnum.UNION ||
        this.areaTypeLevel == AreaFilterTypeEnum.SUB_DISTRICT ||
        this.areaTypeLevel == AreaFilterTypeEnum.DISTRICT ||
        this.areaTypeLevel == AreaFilterTypeEnum.DIVISION
      ) {
        return true;
      } else return false;
    }
    return false;
  }

  getAreaTypeByProject(projectId) {
    this.workingAreaTypes = [];
    this.workingAreaTypesAndStaffs = [];
    // Get working area type
    this.roleHierarchyService.getWorkingAreaTypes(projectId, false).subscribe(
      (result) => {
        if (result.length > 0) {
          this.workingAreaTypes = result;
          result.forEach((obj) => {
            this.workingAreaTypesAndStaffs.push({
              area_filter_type: obj,
              staff_ids: [],
              staff_names: [],
              area_filter_ids: [],
              area_filter_names: [],
            });
          });
          if (this.filterStaff) {
            this.headofficeStaffList = [];
            this.getStaffsByProject(
              this.areaFilterTypeEnum.HEAD_OFFICE,
              [],
            ).subscribe((results) => {
              this.headofficeStaffList = results;
            });
          }
        } else {
          this.utilitiesService.showSwalWithToast(
            'No Working area found',
            'No working area found.',
            'info',
          );
        }
      },
      (err) => {},
    );
  }

  public getStaffsByProject(
    areaFilterType: AreaFilterTypeEnum,
    reporterUserFilterValues: string[],
  ): Observable<any> {
    return this.staffMappingService.getReportingSubordinateUsers(
      this.projectId,
      areaFilterType,
      reporterUserFilterValues,
    );
  }

  findWorkingAreaTypes(areaType: string): boolean {
    if (this.isWorkingTypeFilter) {
      if (this.workingAreaTypes?.length > 0) {
        const index = this.workingAreaTypes.indexOf(areaType);
        if (index !== -1) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    } else {
      return true;
    }
  }
}
