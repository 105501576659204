import { AreaFilterTypeEnum } from '../../enum/area-filter-type.enum';

export interface IAddGeoLocation {
  division_id?: string;
  division_name?: string;
  division_code?: string;
  district_id?: string;
  district_name?: string;
  district_code?: string;
  sub_district_id?: string;
  sub_district_name?: string;
  sub_district_code?: string;
  union_id?: string;
  union_name?: string;
  union_code?: string;
  ward_id?: string;
  ward_name?: string;
  ward_code?: string;
  client_created_at?: string;
  client_updated_at?: string;
}

export class AreaModel {
  filter_type?: string = null;
  filter_value?: { id: string; name: string }[] = [];
  division?: { id: string; name: string }[] = [];
  district?: { id: string; name: string }[] = [];
  sub_district?: { id: string; name: string }[] = [];
  union?: { id: string; name: string }[] = [];
  ward?: { id: string; name: string }[] = [];
}
