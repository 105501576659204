import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AreaFilterTypeEnum } from '../../enum/area-filter-type.enum';
import { Url } from '../../enum/url.enum';
import { HttpRequestService } from '../http-request/http-request.service';
import { UtilitiesService } from '../utilities/utilities.service';

@Injectable({
  providedIn: 'root',
})
export class GeoManagementService {
  geoLocationAPI = Url.CONFIGURATION + '/masterdata/geo-location';
  projectOfficeManagementAPI = Url.CONFIGURATION + '/project-office-management';

  constructor(
    private httpService: HttpRequestService,
    private utilitiesService: UtilitiesService,
  ) {}
  // Geo Location
  async getGeolocationList(): Promise<Observable<any>> {
    return this.httpService.get(this.geoLocationAPI + '/all').pipe(
      map((x: any) => this.utilitiesService.responseHandler(x)),
      map((x: any) =>
        x.sort(this.utilitiesService.dynamicSortWithDate('created_at', 'desc')),
      ),
    );
  }

  getGeolocationDetailsById(id, district_id): Observable<any> {
    return this.httpService
      .get(
        this.geoLocationAPI +
          '/details?district_id=' +
          district_id +
          '&id=' +
          id,
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  createGeolocation(body): Observable<any> {
    return this.httpService
      .post(this.geoLocationAPI + '/create', body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  updateGeolocation(id, district_id, body): Observable<any> {
    return this.httpService
      .patch(
        this.geoLocationAPI +
          '/update?district_id=' +
          district_id +
          '&id=' +
          id,
        body,
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  deleteGeolocation(id, district_id): Observable<any> {
    return this.httpService
      .delete(
        this.geoLocationAPI +
          '/delete?district_id=' +
          district_id +
          '&id=' +
          id,
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  isWardMapped(ward_id): Observable<any> {
    return this.httpService
      .get(this.projectOfficeManagementAPI + '/ward-mapped/' + ward_id)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  getGeoLocationAreaList(filterType: AreaFilterTypeEnum, filterValue?: string) {
    let url = this.geoLocationAPI + '/areas?filter_type=' + filterType;
    url = filterValue ? url + '&filter_value=' + filterValue : url;

    return this.httpService
      .get(url)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
}
