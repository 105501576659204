<div
  class="pulse-loader"
  [style.width]="pulseModel.width"
  [style.height]="pulseModel.height"
  [style.color]="pulseModel.color"
  [style.margin]="pulseModel.margin"
>
  <div></div>
  <div></div>
  <div></div>
</div>
