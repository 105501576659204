import { ITaskManagementMenu } from 'src/app/shared/interface/dashboard-menu/task-management-menu.interface';

export const TASK_MANAGEMENT_MENU: ITaskManagementMenu[] = [
  {
    id: 'da843b9d9bcd76777ede2',
    actions: {
      create: true,
      get: true,
      remove: true,
      update: true,
    },
    is_active: true,
    menu_id: 'w_m6',
    menu_name: 'Task Management',
    menu_order: 6,
    submenu_id: 'w_sm61',
    submenu_name: 'My Task',
    submenu_order: 1,
  },
  {
    id: 'a843b9d9bcd76777ede2d',
    actions: {
      create: true,
      get: true,
      remove: true,
      update: true,
    },
    is_active: true,
    menu_id: 'w_m6',
    menu_name: 'Task Management',
    menu_order: 6,
    submenu_id: 'w_sm62',
    submenu_name: 'Task Assignment',
    submenu_order: 2,
  },
];
