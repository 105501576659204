<footer class="footer" id="footer">
  <!-- <div class="d-sm-flex justify-content-center">
      <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © {{getcurrentYear}}. All rights reserved.</span>
    </div> -->
  <div>
    <span>Powered by Brac Bangladesh</span>
  </div>
  <div>
    <span
      >Developed by
      <a href="http://www.bengalmobileqa.com/" target="_blank">BMQAS</a></span
    >
  </div>
</footer>
