import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MasterDataManagementService } from '../../services/master-data-management/master-data-management.service';
import { AuthService } from '../../services/auth/auth.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';

@Component({
  selector: 'app-programme',
  templateUrl: './programme.component.html',
  styleUrls: ['./programme.component.css'],
})
export class ProgrammeComponent implements OnInit {
  @Input() callFrom: string;
  @Input() userInfo: any;
  @Output() closePopUpCLick: EventEmitter<any> = new EventEmitter<any>();
  programList: any[] = [];
  projectID: any;
  programList_filtered: any = [];
  userMappedProgramList: any;
  sessionUser: any;

  constructor(
    public masterDataManagementService: MasterDataManagementService,
    public router: Router,
    public authService: AuthService,
    public localStorage: LocalStorageService,
  ) {
    this.projectID = this.localStorage.getProjectFromLocalStorage();
    //this.userInfo = this.autenticationService.getUserInfo();
  }

  ngOnInit(): void {
    this.programList = this.userInfo.program_project;
    // this.programList = Array.from(new Set(this.programList.map(s => s.project_id)))
    // .map(id => {
    //   return {
    //     project_id: id,
    //     project_name : this.programList.find(s => s.project_id == id).project_name,
    //   }
    // })

    // If only one project found then its auto selected
    if (this.programList.length == 1) {
      this.projectID = this.programList[0].project_id;
    }
    //this.getProgramList();
  }

  // async getProgramList() {
  //   (await this.masterDataManagementService.getProgramList()).subscribe(
  //     (result) => {
  //       if (result.status == 'ok') {
  //         this.programList = result.resultset.filter(
  //           (data) => data.is_active === true
  //         );

  //         // if(!this.autenticationService.isAdminRole()){
  //         //   this.userMappedProgramList = this.userInfo.program_cohort;
  //         //   this.filterProgramList();
  //         // }
  //       }
  //     },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }

  projectOptionChange(e) {
    this.projectID = e.value;
  }

  onFormSubmit(e) {
    if (this.projectID) {
      this.localStorage.setProjectInLocalStorage(this.projectID);
      this.authService.setUserDataOnLocalStorageObs(true);
      this.masterDataManagementService.setwebACLTriggerObs(false);

      if (this.callFrom != 'signin') {
        this.sessionUser = this.localStorage.getSessionUser();

        let selectedProject = this.sessionUser.program_project.filter(
          (s) =>
            s.project_id === this.localStorage.getProjectFromLocalStorage(),
        );
        if (selectedProject.length > 0) {
          // found
          this.sessionUser.role_id = selectedProject[0].role_id;
          this.sessionUser.role.role_ids = selectedProject[0].role_ids;
          this.sessionUser.role.role_id = selectedProject[0].role_id;
          this.sessionUser.role.role_name = selectedProject[0].role_name;
          this.sessionUser.role.role_short_form =
            selectedProject[0].role_short_form;
          this.sessionUser.role.short_form = selectedProject[0].role_short_form;
          this.localStorage.setSessionUser(this.sessionUser);
        }
        this.masterDataManagementService.setwebACLTriggerObs(true);
      }
      this.router.navigateByUrl('/dashboard');
      this.closePopUpCLick.emit();
    }
  }

  // filterProgramList() {
  //   this.programList_filtered = this.programList.filter(({ id: id1 }) => !this.userMappedProgramList.some(({ program_id: id2 }) => id2 != id1));

  // }
}
