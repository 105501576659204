import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, mergeMap, of } from 'rxjs';
import { IFieldMonitoringQcReportQuery } from 'src/app/modules/dashboard/report/field-monitoring-qc-report/interface/field-monitoring-qc-report-query.interface';
import { IIndividualStaffReportQuery } from 'src/app/modules/dashboard/report/individual-staff-report/interface/individual-staff-report-query.interface';
import { AreaFilterTypeEnum } from '../../enum/area-filter-type.enum';
import { Url } from '../../enum/url.enum';
import { HttpRequestService } from '../http-request/http-request.service';
import { UtilitiesService } from '../utilities/utilities.service';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private reloadActivityTreeSubject = new BehaviorSubject<boolean>(false);
  reloadActivityTree$ = this.reloadActivityTreeSubject.asObservable();

  private reportAPI: string = '';

  constructor(
    private readonly httpService: HttpRequestService,
    private readonly utilitiesService: UtilitiesService,
    private http: HttpClient,
  ) {}

  triggerActivityTreeReload() {
    this.reloadActivityTreeSubject.next(true);
  }

  public getProjectWiseMonthlyReport(body: any): Observable<any> {
    return this.httpService
      .get(
        Url.REPORT +
          '/project-wise-monthly-progress-report?activity_ids=' +
          body.activity_ids +
          '&activity_category_ids=' +
          body.activity_category_ids +
          '&activity_type_ids=' +
          body.activity_type_ids +
          '&area_filter_type=' +
          body.area_filter_type +
          '&area_filter_values=' +
          body.area_filter_values +
          '&project_id=' +
          body.project_id +
          '&date=' +
          body.start,
      )
      .pipe(
        map((x: any) => this.utilitiesService.responseHandler(x)),
        map((x: any) => {
          return x;
        }),
      );
  }

  public getActivityWiseBeneficiaryCoverageReport(body: any): Observable<any> {
    return this.httpService
      .get(
        Url.REPORT +
          '/area-wise-beneficiary-report?activity_ids=' +
          body.activity_ids +
          '&activity_type_ids=' +
          body.activity_type_ids +
          '&area_filter_type=' +
          body.area_filter_type +
          '&area_filter_values=' +
          body.area_filter_values +
          '&project_id=' +
          body.project_id +
          '&start=' +
          body.start +
          '&end=' +
          body.end +
          '&activity_category_ids=' +
          body.activity_category_ids,
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getAreaWiseProgressReport(body: any): Observable<any> {
    return this.httpService
      .get(
        Url.REPORT +
          '/area-wise-progress-report?activity_ids=' +
          body.activity_ids +
          '&activity_type_ids=' +
          body.activity_type_ids +
          '&area_filter_type=' +
          body.area_filter_type +
          '&area_filter_values=' +
          body.area_filter_values +
          '&project_id=' +
          body.project_id +
          '&start=' +
          body.start +
          '&end=' +
          body.end,
      )
      .pipe(
        map((x: any) => this.utilitiesService.responseHandler(x)),
        map((x: any) => {
          return {
            ...x,
            report_summaries: this.utilitiesService.getSlNo(x.report_summaries),
          };
        }),
      );
  }

  public getProjectAreaWiseStaff(
    body: any,
    is_only_type = true,
  ): Observable<any> {
    const filter_type =
      body.filter_type === AreaFilterTypeEnum.SUB_DISTRICT
        ? AreaFilterTypeEnum.UPAZILA
        : body.filter_type;

    return this.httpService
      .get(
        Url.AUTH +
          '/staff-mapping/project-area-wise-staffs?project_id=' +
          body.project_id +
          '&filter_type=' +
          filter_type +
          '&is_only_type=' +
          is_only_type +
          '&filter_values=' +
          body.filter_value,
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getStaffWiseProgressReport(body: any): Observable<any> {
    return this.httpService
      .get(
        Url.REPORT +
          '/staff-wise-progress-report?activity_ids=' +
          body.activity_ids +
          '&activity_type_ids=' +
          body.activity_type_ids +
          '&project_id=' +
          body.project_id +
          '&start=' +
          body.start +
          '&end=' +
          body.end +
          '&staff_ids=' +
          body.staff_ids +
          '&user_area_filter_type=' +
          body.area_filter_type +
          '&user_area_filter_values=' +
          (body.area_filter_type === AreaFilterTypeEnum.HEAD_OFFICE
            ? ''
            : body.area_filter_ids),
      )
      .pipe(
        map((x: any) => this.utilitiesService.responseHandler(x)),
        map((x: any) => this.utilitiesService.getSlNo(x)),
        mergeMap((x) => {
          x?.forEach((y: any) => {
            y.activity_wise_summary_history = this.utilitiesService.getSlNo(
              y.activity_wise_summary_history || [],
            );

            y.activity_wise_summary_history = this.modifyTotalRowOfReport(
              y.activity_wise_summary_history || [],
              'head_office_user_role_name',
            );
            if (y.working_area_type) {
              let key;
              if (y.working_area_type === AreaFilterTypeEnum.UPAZILA) {
                key = 'sub_district';
              } else {
                key = y.working_area_type.toLowerCase();
              }
              y[key + '_user_name'] = y.name;
              y[key + '_user_pin'] = y.pin;
              y[key + '_user_role_name'] = y.role_name;
              y[key + 's'] = y[key + '_name'];

              y.activity_wise_summary_history?.forEach((z: any) => {
                z[key + '_user_name'] = z.name;
                z[key + '_user_pin'] = z.pin;
                z[key + '_user_role_name'] = z.role_name;
                z[key + 's'] = z[key + '_name'];
              });
            }
          });
          return of(
            this.modifyTotalRowOfReport(x, 'head_office_user_role_name'),
          );
        }),
      );
  }

  public modifyTotalRowOfReport(arr: any[], key_name: string): any[] {
    if (arr.length > 0) {
      const x = JSON.parse(
        JSON.stringify(arr[arr?.length - 1].activity_wise_summary),
      );
      Object.keys(arr[arr?.length - 1]).forEach((key) => {
        arr[arr?.length - 1][key] = ' ';
      });
      arr[arr?.length - 1][key_name] = 'Grand Total';
      arr[arr?.length - 1].activity_wise_summary = x;
    }
    return arr;
  }

  public getUpazilaUnionWiseActivityReport(body: any): Observable<any> {
    return this.httpService
      .get(
        Url.REPORT +
          '/area-wise-activity-report?activity_ids=' +
          body.activity_ids +
          '&activity_type_ids=' +
          body.activity_type_ids +
          '&area_filter_type=' +
          body.area_filter_type +
          '&area_filter_values=' +
          body.area_filter_values +
          '&project_id=' +
          body.project_id +
          '&start=' +
          body.start +
          '&end=' +
          body.end,
      )
      .pipe(
        map((x: any) => this.utilitiesService.responseHandler(x)),
        map((x: any) => {
          return {
            ...x,
            report_summaries: this.utilitiesService.getSlNo(x.report_summaries),
          };
        }),
      );
  }

  public getIndividualStaffReport(
    query: IIndividualStaffReportQuery,
  ): Observable<any> {
    return this.httpService
      .get(
        `${Url.REPORT}/individual-staff-report?user_id=${query.user_id}&project_ids=${query.project_ids}&start=${query.start}&end=${query.end}`,
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getFieldMonitoringQcReport(
    query: IFieldMonitoringQcReportQuery,
  ): Observable<any> {
    return this.httpService
      .get(
        `${Url.REPORT}/qc-report?project_id=${query.project_id}&start=${query.start}&end=${query.end}&activity_ids=${query.activity_ids}&activity_type_ids=${query.activity_type_ids}&area_filter_type=${query.area_filter_type}&area_filter_values=${query.area_filter_values}&is_detailed_report=${query.is_detailed_report}&statuses=${query.statuses}`,
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
}
