import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AclService } from 'src/app/shared/services/acl/acl.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { MasterDataManagementService } from 'src/app/shared/services/master-data-management/master-data-management.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import { TASK_MANAGEMENT_MENU } from './task-management-menu';
let staticMenuData = require('./staticData.json');
@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrls: ['./dashboard-menu.component.css'],
})
export class DashboardMenuComponent implements OnInit {
  programID: any;
  aclFlatList: any[] = [];
  aclTreeList: any[] = [];
  roleIds: any;
  sessionUser: any;
  staticMenuData: any[] = [];
  unsubscribe$: Subject<boolean> = new Subject();
  showCross: boolean;

  constructor(
    route: Router,
    public masterDataService: MasterDataManagementService,
    public autenticationService: AuthService,
    public utilService: UtilitiesService,
    public localStorageService: LocalStorageService,
    public aclService: AclService,
  ) {
    this.sessionUser = this.localStorageService.getSessionUser();
    this.checkRouter(route);
    this.staticMenuData = staticMenuData;
  }

  ngOnInit(): void {
    // {
    //   "menu_id": "",
    //   "menu_name": "Master Data Management",
    //   "routerUrl": "",
    //   "routerId": [must be unique and match with router],
    //   "icon": "master_data_management.svg",
    //   "is_active": true
    // },
    this.masterDataService
      .getwebACLTriggerObs()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          this.sessionUser = this.localStorageService.getSessionUser();
          this.getACLByRoleId();
        }
      });
    this.getACLByRoleId();
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  collapsiableMenuItemJS() {
    var coll = document.getElementsByClassName('collapsible');
    var i;
    const that = this;
    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener('click', function (this: any) {
        that.makeActiveDefaultIconForMenuClick(this);

        this.parentElement.classList.toggle('active');
        //var content = this.nextElementSibling;
        //this.nextSibling.classList.toggle('show');
        if (this.nextSibling.classList.contains('show')) {
          this.nextSibling.classList.remove('show');
        } else {
          this.nextSibling.classList.add('show');
        }
      });
    }
  }

  checkRouter(route: any) {
    var scrollId: any;
    if (this.isExactMatch(route.url, 'welcome')) {
      scrollId = 'welcome';
    } else if (this.isExactMatch(route.url, 'project-config')) {
      scrollId = 'project-config';
    } else if (this.isExactMatch(route.url, 'cohort-list')) {
      scrollId = 'cohort-list';
    } else if (this.isExactMatch(route.url, 'geolocationList')) {
      scrollId = 'geolocationList';
    } else if (this.isExactMatch(route.url, 'project-form-mapping')) {
      scrollId = 'project-form-mapping';
    } else if (this.isExactMatch(route.url, 'project')) {
      scrollId = 'project';
    } else if (this.isExactMatch(route.url, 'donor')) {
      scrollId = 'donor';
    } else if (this.isExactMatch(route.url, 'project-activity-mapping')) {
      scrollId = 'project-activity-mapping';
    } else if (this.isExactMatch(route.url, 'activity-configuration')) {
      scrollId = 'activity-configuration';
    } else if (this.isExactMatch(route.url, 'user')) {
      scrollId = 'user';
    } else if (this.isExactMatch(route.url, 'project-office')) {
      scrollId = 'project-office';
    } else if (this.isExactMatch(route.url, 'additional-field-structure')) {
      scrollId = 'additional-field-structure';
    } else if (this.isExactMatch(route.url, 'beneficiary-profile')) {
      scrollId = 'beneficiary-profile';
    } else if (this.isExactMatch(route.url, 'beneficiary-project-mapping')) {
      scrollId = 'beneficiary-project-mapping';
    } else if (this.isExactMatch(route.url, 'zone-district-mapped-list')) {
      scrollId = 'zone-district-mapped-list';
    } else if (
      this.isExactMatch(route.url, 'branch-sub-district-mapped-list')
    ) {
      scrollId = 'branch-sub-district-mapped-list';
    } else if (this.isExactMatch(route.url, 'zone-region-branch-list')) {
      scrollId = 'zone-region-branch-list';
    } else if (this.isExactMatch(route.url, 'catchment-area-setup-list')) {
      scrollId = 'catchment-area-setup-list';
    } else if (this.isExactMatch(route.url, 'designation')) {
      scrollId = 'designation';
    } else if (this.isExactMatch(route.url, 'designation-hierarchy')) {
      scrollId = 'designation-hierarchy';
    } else if (this.isExactMatch(route.url, 'accessControl')) {
      scrollId = 'accessControl';
    } else if (this.isExactMatch(route.url, 'staff-mapping')) {
      scrollId = 'staff-mapping';
    } else if (this.isExactMatch(route.url, 'staff-transfer')) {
      scrollId = 'staff-transfer';
    } else if (this.isExactMatch(route.url, 'followup-questionnaire-config')) {
      scrollId = 'followup-questionnaire-config';
    } else if (this.isExactMatch(route.url, 'swot-configuration')) {
      scrollId = 'swot-configuration';
    } else if (this.isExactMatch(route.url, 'home-visit-steps-config')) {
      scrollId = 'home-visit-steps-config';
    } else if (
      this.isExactMatch(route.url, 'home-visit-step-question-config')
    ) {
      scrollId = 'home-visit-step-question-config';
    } else if (this.isExactMatch(route.url, 'graduation-configuration')) {
      scrollId = 'graduation-configuration';
    } else if (this.isExactMatch(route.url, 'committee-formation')) {
      scrollId = 'committee-formation';
    } else if (this.isExactMatch(route.url, 'meeting-activity-management')) {
      scrollId = 'meeting-activity-management';
    } else if (
      this.isExactMatch(route.url, 'approval-hierarchy-configuration')
    ) {
      scrollId = 'approval-hierarchy-configuration';
    } else if (this.isExactMatch(route.url, 'groups')) {
      scrollId = 'groups';
    } else if (this.isExactMatch(route.url, 'asset')) {
      scrollId = 'asset';
    } else if (this.isExactMatch(route.url, 'enterprise-category')) {
      scrollId = 'enterprise-category';
    } else if (this.isExactMatch(route.url, 'enterprise')) {
      scrollId = 'enterprise';
    } else if (this.isExactMatch(route.url, 'enterprise-mappings')) {
      scrollId = 'enterprise-mappings';
    } else if (this.isExactMatch(route.url, 'materials')) {
      scrollId = 'materials';
    } else if (this.isExactMatch(route.url, 'material-and-home-visit')) {
      scrollId = 'material-and-home-visit';
    } else if (this.isExactMatch(route.url, 'material-and-group-visit')) {
      scrollId = 'material-and-group-visit';
    } else if (this.isExactMatch(route.url, 'trainings')) {
      scrollId = 'trainings';
    } else if (this.isExactMatch(route.url, 'input-category')) {
      scrollId = 'input-category';
    } else if (this.isExactMatch(route.url, 'inputs')) {
      scrollId = 'inputs';
    } else if (this.isExactMatch(route.url, 'asset-and-input-mapping')) {
      scrollId = 'asset-and-input-mapping';
    } else if (this.isExactMatch(route.url, 'pre-requisites')) {
      scrollId = 'pre-requisites';
    } else if (this.isExactMatch(route.url, 'selection-criteria')) {
      scrollId = 'selection-criteria';
    } else if (this.isExactMatch(route.url, 'reason-type')) {
      scrollId = 'reason-type';
    } else if (this.isExactMatch(route.url, 'reason')) {
      scrollId = 'reason';
    } else if (this.isExactMatch(route.url, 'exclusion')) {
      scrollId = 'exclusion';
    } else if (this.isExactMatch(route.url, 'support-item')) {
      scrollId = 'support-item';
    } else if (this.isExactMatch(route.url, 'wealth-rank')) {
      scrollId = 'wealth-rank';
    } else if (this.isExactMatch(route.url, 'form-menu-mapping')) {
      scrollId = 'form-menu-mapping';
    } else if (this.isExactMatch(route.url, 'form')) {
      scrollId = 'form';
    } else if (this.isExactMatch(route.url, 'enterprise-selection-report')) {
      scrollId = 'enterprise-selection-report';
    } else if (this.isExactMatch(route.url, 'enterprise-growth-report')) {
      scrollId = 'enterprise-growth-report';
    } else if (this.isExactMatch(route.url, 'asset-growth-report')) {
      scrollId = 'asset-growth-report';
    } else if (this.isExactMatch(route.url, 'training-report')) {
      scrollId = 'training-report';
    } else if (this.isExactMatch(route.url, 'pra-report')) {
      scrollId = 'pra-report';
    } else if (this.isExactMatch(route.url, 'report/exclusion-report')) {
      scrollId = 'report/exclusion-report';
    } else if (this.isExactMatch(route.url, 'asset-trans-report')) {
      scrollId = 'asset-trans-report';
    } else if (this.isExactMatch(route.url, 'asset-trans-report-by-group')) {
      scrollId = 'asset-trans-report-by-group';
    } else if (this.isExactMatch(route.url, 'asset-trans-summary-report')) {
      scrollId = 'asset-trans-summary-report';
    } else if (
      this.isExactMatch(route.url, 'report/asset-diversification-report')
    ) {
      scrollId = 'report/asset-diversification-report';
    } else if (this.isExactMatch(route.url, 'special-report')) {
      scrollId = 'special-report';
    } else if (this.isExactMatch(route.url, 'vssc-report')) {
      scrollId = 'vssc-report';
    } else if (this.isExactMatch(route.url, 'health-care-report')) {
      scrollId = 'health-care-report';
    } else if (this.isExactMatch(route.url, 'graduation-report')) {
      scrollId = 'graduation-report';
    } else if (this.isExactMatch(route.url, 'graduation-report-group-one')) {
      scrollId = 'graduation-report-group-one';
    } else if (this.isExactMatch(route.url, 'ssnp-report')) {
      scrollId = 'ssnp-report';
    } else if (this.isExactMatch(route.url, 'participant-profile')) {
      scrollId = 'participant-profile';
    } else if (this.isExactMatch(route.url, 'checklist-field-setup')) {
      scrollId = 'checklist-field-setup';
    } else if (this.isExactMatch(route.url, 'checklist-field-data')) {
      scrollId = 'checklist-field-data';
    } else if (this.isExactMatch(route.url, 'checklist-action-plan')) {
      scrollId = 'checklist-action-plan';
    } else if (this.isExactMatch(route.url, 'team-action-plan')) {
      scrollId = 'team-action-plan';
    } else if (this.isExactMatch(route.url, 'supervision-action-plan-setup')) {
      scrollId = 'supervision-action-plan-setup';
    } else if (this.isExactMatch(route.url, 'action-plan-type-setup')) {
      scrollId = 'action-plan-type-setup';
    } else if (this.isExactMatch(route.url, 'group-visit-topic-setup')) {
      scrollId = 'group-visit-topic-setup';
    } else if (this.isExactMatch(route.url, 'send-approval-request')) {
      scrollId = 'send-approval-request';
    } else if (
      this.isExactMatch(route.url, 'waiting-for-my-approval/general-purchase')
    ) {
      scrollId = 'waiting-for-my-approval-general-purchase';
    } else if (
      this.isExactMatch(
        route.url,
        'waiting-for-my-approval/second-round-asset-support',
      )
    ) {
      scrollId = 'waiting-for-my-approval-second-round-asset-support';
    } else if (
      this.isExactMatch(route.url, 'my-approval-history/general-purchase')
    ) {
      scrollId = 'my-approval-history-general-purchase';
    } else if (
      this.isExactMatch(
        route.url,
        'my-approval-history/second-round-asset-support',
      )
    ) {
      scrollId = 'my-approval-history-second-round-asset-support';
    } else if (this.isExactMatch(route.url, 'correction')) {
      scrollId = 'correction';
    } else if (this.isExactMatch(route.url, 'audit-trail')) {
      scrollId = 'audit-trail';
    } else if (this.isExactMatch(route.url, 'cohort/asset-wise-growth-form')) {
      scrollId = 'cohort-asset-wise-growth-form';
    } else if (this.isExactMatch(route.url, 'task-management')) {
      scrollId = 'task-management';
    } else if (this.isExactMatch(route.url, 'task-management/my-task')) {
      scrollId = 'my-task';
    } else if (this.isExactMatch(route.url, 'task-management/task-assignment')) {
      scrollId = 'task-assignment';
    } else {
      //scrollId = '';
    }

    // scroll to element.
    setTimeout(() => {
      //this.scrollElement(scrollId);
    }, 2000);
  }

  escapeRegExpMatch(s: any) {
    return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  }

  isExactMatch(str: any, match: any) {
    return new RegExp(`\\b${this.escapeRegExpMatch(match)}\\b`).test(str);
  }

  scrollElement(elemId: string) {
    // scrollIntoViewOptions Optional
    //  A Boolean or an object with the following options:
    //  {
    //    behavior: "auto"  | "instant" | "smooth",
    //    block:    "start" | "center" | "end" | "nearest",
    //    inline:    "start" | "center" | "end" | "nearest",
    //  }
    var element: any = document.getElementById(elemId);
    element.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }

  // WEB ACL
  public onCompare(
    _left: KeyValue<any, any>,
    _right: KeyValue<any, any>,
  ): number {
    return 1; // This is for asecding order
    // return -1; // // This is for dsecding order
  }

  getACLByRoleId(): any {
    this.utilService.showSpinner(true);
    this.roleIds = this.autenticationService.isAdminRole() ?  this.sessionUser?.role?.role_id : this.sessionUser?.role?.role_ids.toString();
    this.programID = this.localStorageService.getProjectFromLocalStorage(); 

    this.aclService.getAllACLByRoleIds(this.programID, this.roleIds,this.autenticationService.isAdminRole()).subscribe(
      (result: any) => {
        if (result.status == 'ok') {
          //this.aclList = result.resultset;
          this.aclFlatList = result.resultset;

          this.aclFlatList = this.aclFlatList.filter(s => s.is_active == true);

          //if((this.sessionUser?.program_project.length > 0) || (this.sessionUser?.role?.role_short_form.toString().toLowerCase() == 'ho') || (this.sessionUser?.role?.role_short_form.toString().toLowerCase() == 'admin')){
            this.aclFlatList.push(...TASK_MANAGEMENT_MENU);
          //}

          // Sort menu list which have contain w_
          this.aclFlatList = this.aclFlatList.filter(function (obj) {
            //check if object value contains value you are looking for
            if (obj['menu_id'].includes('w_')) {
              //add this object to the filtered array
              return obj;
            }
          });

          // Sort menu list which have 'get' true
          this.aclFlatList = this.aclFlatList.filter(function (obj) {
            //check if object value contains value you are looking for
            if (obj['actions'].get == true) {
              // show menu only get is true.
              return obj;
            }
          });

          //console.log(this.aclFlatList);
          this.localStorageService.setACLMenuInfo(this.aclFlatList);
          this.aclHirerchyDataReady(this.aclFlatList);
        }
      },
      (err) => {
        //console.log(err);
      },
    );
  }

  aclHirerchyDataReady(list: any) {
    let finalArray = [];
    const arrayDummy = list;
    // Step: 1 => GroupBy Full list of array.
    var groupBy = this.groupBy(list, 'menu_id');
    //console.log(groupBy);
    // Step: 2 => For loop with GroupBy Object Length.
    for (let i = 0; i < Object.keys(groupBy).length; i++) {
      const element = Object.keys(groupBy)[i];
      // Step: 3 => Find Index  to check menuid(element) is on any submenu_id.
      const findIndex = arrayDummy.findIndex(
        (data: any) => data.submenu_id == element,
      );
      if (findIndex == -1) {
        // Step: 4 => If not then bind find its children
        var x = this.getNestedChildren(list, element);
        // Step: 5 => finnaly Push the result to a array and flat.
        x = x.sort((a, b) =>
          a === b ? 0 : a.menu_order < b.menu_order ? 1 : 1,
        );
        finalArray.push(...x);
      }
    }
    //this.aclTreeList = finalArray;
    //console.log(finalArray);

    // finalArray = finalArray.sort(this.utilService.dynamicSort('menu_order'));
    finalArray = finalArray.sort((a, b) =>
      a === b ? 0 : a.menu_order < b.menu_order ? 1 : 1,
    ); // Issue raise with default sort in Mozila (Ticket No: BW-1194)
    //console.log(finalArray);
    //this.aclTreeList = finalArray;

    this.aclTreeList = this.groupBy(finalArray, 'menu_id');
    //console.log(this.aclTreeList);

    setTimeout(() => {
      this.collapsiableMenuItemJS();
      //this.changeMenuIconActivebyClickMenu_Collapsible();
      this.changeMenuIconActivebyClickMenu_SingleMenu();
      this.utilService.showSpinner(false);
    }, 1000);
    // const entries = Object.entries(groupByFinal);
    // console.log(entries);

    //this.dataSourceBackUp = finalArray;
    //this.generateTreeGrid(this.aclTreeList);
    //this.loadACLModule(this.aclTreeList);
  }

  groupBy(arr: any, criteria: any) {
    const newObj = arr.reduce(function (acc: any, currentValue: any) {
      if (!acc[currentValue[criteria]]) {
        acc[currentValue[criteria]] = [];
      }
      acc[currentValue[criteria]].push(currentValue);
      return acc;
    }, {});
    return newObj;
  }

  getNestedChildren(arr: any, parent: any) {
    var out = [];
    for (var i in arr) {
      if (arr[i].menu_id == parent) {
        arr[i].actions.subModule = false;
        var children = this.getNestedChildren(arr, arr[i].submenu_id);

        if (children.length) {
          arr[i].children = children;
        }
        out.push(arr[i]);
      }
    }
    return out;
  }

  getMenuStaticData(menuId: string) {
    const findIndex = this.staticMenuData.findIndex(
      (data) => data.menu_id == menuId,
    );
    if (findIndex != -1) {
      // Found
      return this.staticMenuData[findIndex];
    } else {
      return false;
    }
  }

  menuClick(e: any) {
    //console.log(e);
  }

  changeMenuIconActivebyClickMenu_Collapsible() {
    // Get all elements with the class "nav-link"
    const collapsibles = document.getElementsByClassName('collapsible');
    const that = this;

    // Add click event listener to each "nav-link"
    for (let i = 0; i < collapsibles.length; i++) {
      collapsibles[i].addEventListener('click', function (this: any) {
        that.makeActiveDefaultIconForMenuClick(this);
      });
    }
  }

  changeMenuIconActivebyClickMenu_SingleMenu() {
    // Get all elements with the class "nav-link"
    const singleMenus = document.getElementsByClassName('singleMenu');
    let previousEvent = null;
    const that = this;
    // Add click event listener to each "nav-link"
    for (let i = 0; i < singleMenus.length; i++) {
      singleMenus[i].addEventListener('click', function (this: any) {
        if (previousEvent)
          that.makeActiveDefaultIconForMenuClick(previousEvent);

        that.makeActiveDefaultIconForMenuClick(this);

        previousEvent = this;
      });
    }
  }

  makeActiveDefaultIconForMenuClick(event: any) {
    // Access the immediate sibling with class "menuItem"
    const menuIcon = event.querySelector('.menuIcon');

    // split the link by /  and get last part
    const srcParts = menuIcon.src.split('/');
    const imageNameFull = srcParts[srcParts.length - 1];
    srcParts.pop(); // remove that last path

    let newPath: any = srcParts.join('/');

    // now split lastPartOfLink

    const imageNameFullSplit = imageNameFull.split('.'); // dashboard_Active.svg
    const imageName = imageNameFullSplit[0]; // dashboard_Active
    const imageExtention = imageNameFullSplit[1]; // svg

    const imageNameSplit = imageName.split('_'); // ['dashboard', 'active']

    if (imageNameSplit[imageNameSplit.length - 1] == 'active') {
      // its already active, make it default
      imageNameSplit.pop(); // remove last 'active' part

      newPath = newPath + '/' + imageNameSplit.join('_') + '.' + imageExtention;
    } else {
      // make it active
      newPath =
        newPath + '/' + imageNameSplit.join('_') + '_active.' + imageExtention;
    }
    // You can also update the image source using:
    menuIcon.src = newPath;
  }
}
