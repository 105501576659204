interface IList {
  created_at: Date;
  updated_at: Date;
}

export class CommonUtils {
  public static getMapped(list: any[], key: string): Map<string, any> {
    return list.reduce((map: Map<string, any> | any, currentValue: any) => {
      const idKey: string = currentValue[key].toString();
      map[idKey] = currentValue;
      return map;
    }, {});
  }

  public static isJsonString(str: string) {
    try {
      if (!str) return false;
      const parsedJson = JSON.parse(str);
      return typeof parsedJson === 'object';
    } catch (e) {
      return false;
    }
  }

  public static getUniqueByKey(arr: any[], key: string) {
    const uniqueObj: any = {};

    arr.forEach((obj) => {
      const value = obj[key];
      uniqueObj[value] = uniqueObj[value] || obj;
    });

    return Object.values(uniqueObj);
  }

  public static getSortedListByUpdatedOrCreatedTime<T extends IList>(
    list: T[],
  ): T[] {
    return list.sort((a, b) => {
      const date1 = new Date(a.updated_at || a.created_at).getTime();
      const date2 = new Date(b.updated_at || b.created_at).getTime();

      return date2 - date1;
    });
  }

  public static groupBy(array: any[], key: string) {
    return array.reduce((result, currentValue) => {
      const keyValue = currentValue[key].toString();
      (result[keyValue] = result[keyValue] || []).push(currentValue);
      return result;
    }, {});
  }
}
