import { AdditionalFieldStructureListComponent } from "src/app/modules/dashboard/operation-management/additional-field-structure/additional-field-structure-list/additional-field-structure-list.component";

export enum FileUploadSubCategory {
  STAFF_MAPPING = 'staff-mapping',
  USERS = 'users',
  BRANCH_SUB_DISTRICT = 'branch-sub-district',
  ZONE_REGION_BRANCH = 'zone-region-branch',
  DISTRICT_ZONE = 'district-zone',
  GEO_LOCATION = 'geolocation',
  PROJECT_OFFICE_MANAGEMENT = 'project-office-management',
  ADDITIONAL_FIELD_STRUCTURE = 'additional-field-structure',
  TARGET_SETUP = 'target-setup',
}
