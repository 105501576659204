import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { environment } from '../../../../../environments/environment';
import { LocalStorageService } from '../../../../shared/services/local-storage/local-storage.service';
import { NotificationService } from '../../../../shared/services/notification/notification.service';
import { UtilitiesService } from '../../../../shared/services/utilities/utilities.service';
@Component({
  selector: 'app-notification-main',
  templateUrl: './notification-main.component.html',
  styleUrls: ['./notification-main.component.css'],
})
export class NotificationMainComponent implements OnInit, OnDestroy {
  message: any;
  unregisterModel = {
    user_id: '',
    device_id: '',
  };
  registerModel = {
    user_id: '',
    device_id: '',
    reg_token: '',
    device: '',
    type: '',
  };
  deviceInfo: DeviceInfo;
  sessionUser: any;
  notificationList: any[] = [];
  constructor(
    private readonly notificationService: NotificationService,
    private readonly deviceService: DeviceDetectorService,
    private readonly utilitiesService: UtilitiesService,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
  ) {
    this.sessionUser = this.localStorageService.getSessionUser();
  }

  ngOnInit(): void {
    this.requestPermission();
    this.listen();

    this.deviceInfo = this.deviceService.getDeviceInfo();
    //console.log(this.deviceInfo);

    this.getAllNotificationByUser(this.sessionUser?.id);
  }

  ngOnDestroy() {
    this.unRegisterNotificationServer();
  }

  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: environment.firebase.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          //console.log('Hurraaa!!! we got the token.....');

          //console.log(currentToken);
          this.registerNotificationServer(currentToken);
        } else {
          //console.log(
          //  'No registration token available. Request permission to generate one.',
          //);
        }
      })
      .catch((err) => {
        //console.log('An error occurred while retrieving token. ', err);
      });
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      this.message = payload;
      const notificationData = payload?.data['data']
        ? JSON.parse(payload?.data['data'])
        : null;
      this.getAllNotificationByUser(this.sessionUser?.id);
      this.utilitiesService.showSwalWithToast(
        'Notification!',
        notificationData?.title
          ? notificationData.title
          : 'You have a new notification.',
        'info',
      );
    });
  }

  registerNotificationServer(regFirebaseToken: any) {
    this.registerModel.reg_token = regFirebaseToken;
    this.registerModel.device = this.deviceInfo.userAgent;
    this.registerModel.device_id = this.deviceInfo.userAgent;
    this.registerModel.user_id = this.sessionUser?.id;
    this.registerModel.type = this.deviceInfo.deviceType;
    this.notificationService
      .registerNotification(this.registerModel)
      .subscribe((result) => {
        console.log(result);
        if (result) {
          console.log('register done to notification server.');
        }
      });
  }

  unRegisterNotificationServer() {
    // console.log(navigator.mediaDevices.enumerateDevices());
    console.log(navigator.mediaDevices);

    this.unregisterModel.user_id = this.sessionUser?.id;
    this.unregisterModel.device_id = this.deviceInfo.userAgent;

    this.notificationService
      .unregisterNotification(this.unregisterModel)
      .subscribe((result) => {
        console.log(result);
        if (result) {
          console.log('Unregister from notification server.');
        }
      });
  }

  public getAllNotificationByUser(userId: string) {
    this.notificationService
      .getAllNotificationByUser(userId)
      .subscribe((result) => {
        if (result) {
          let unseenNotificationsCount = 0;
          this.notificationList = result.map((element) => {
            if (!element?.is_seen) {
              unseenNotificationsCount++;
            }
            return { ...element, data: JSON.parse(element.data) };
          });
          //Sort by Notify Type
          this.notificationList = this.notificationList.sort(
            this.utilitiesService.dynamicSort('notify_type'),
          );
          // Then sort by created Date Desc.
          this.notificationList = this.notificationList.sort((a, b) => {
            const date1: any = new Date(a.created_at);
            const date2: any = new Date(b.created_at);

            return date2 - date1;
          });

          if (unseenNotificationsCount > 0) {
            this.notificationService.setNotificationDot(true);
            this.notificationService.unseenNotificationsCount =
              unseenNotificationsCount;
          }
        }
      });
  }

  // detailsPage(notification: any) {
  //   const waitingForMyApprovalBaseUrl =
  //     'dashboard/approval/waiting-for-my-approval';

  //   const notifyType = notification?.notify_type?.toLowerCase();

  //   for (const key in NotifyTypeIncludesEnum) {
  //     if (notifyType?.includes(NotifyTypeIncludesEnum[key])) {
  //       const notificationNavigate =
  //         notificationNavigateDetails[NotifyTypeIncludesEnum[key]];

  //       this.router.navigateByUrl(
  //         `${waitingForMyApprovalBaseUrl}/${notificationNavigate.url}?${
  //           notificationNavigate.queryParamsKey[0]
  //         }=${notification?.data?.[notificationNavigate.idKey]}`
  //       );

  //       break;
  //     }
  //   }
  // }

  public updateIsSeen(notification) {
    if (notification?.is_seen) {
      return;
    }

    this.notificationService
      .updateIsSeen(notification?.user_id, notification?.notification_id)
      .subscribe((result) => {
        if (result?.result) {
          const index = this.notificationList.findIndex(
            (x) => x?.notification_id === notification?.notification_id,
          );
          this.notificationList[index].is_seen = true;
          this.notificationService.setUnseenNotificationsCount('minus');
        }
      });
  }
}
