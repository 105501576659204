import { Component, Input, OnInit } from '@angular/core';
import { IPulseModel } from '../../interface/pulse-model.interface';

@Component({
  selector: 'app-pulse-loading',
  templateUrl: './pulse-loading.component.html',
  styleUrls: ['./pulse-loading.component.css'],
})
export class PulseLoadingComponent implements OnInit {
  @Input('pulseModel') pulseModel: IPulseModel = {
    width: '50px',
    height: '15px',
    color: '#E80188',
    margin: 'auto',
  };

  constructor() {}

  ngOnInit(): void {}
}
