import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportSharedService {

  private methodCallSource  = new Subject<string>();
  methodCall$ = this.methodCallSource .asObservable();

  instruct(message: string) {
    this.methodCallSource.next(message);
  }
}
