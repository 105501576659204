<nav
  class="sidebar sidebar-offcanvas"
  id="sidebar"
  style="box-shadow: 0 0 8px 7px rgb(194 195 198 / 80%)"
>
<div>
  <ul class="nav flex-column sub-menu" style="padding: 0">
    <li class="nav-item cursor" id="welcome">
      <a
        class="nav-link singleMenu"
        [routerLink]="['/dashboard/home']"
        routerLinkActive="active"
        #Class="routerLinkActive"
      >
        <span
          ><img
            class="menuIcon"
            src="../../../../assets/images/menu-icons/dashboard.svg"
            alt="icon"
        /></span>
        <!-- <i class="dx-icon-rowfield menu-icon menuIcon"></i> -->

        <span class="menu-title">Dashboard</span>
      </a>
    </li>

    <span *ngFor="let aclItem of aclTreeList | keyvalue: onCompare">
      <span>
        <li class="nav-item cursor" *ngIf="getMenuStaticData(aclItem.key)">
          <a class="nav-link collapsible">
            <!-- <i class="dx-icon-fieldchooser menu-icon menuIcon"></i> -->
            <span
              ><img
                class="menuIcon"
                [src]="
                  '../../../../assets/images/menu-icons/' +
                  getMenuStaticData(aclItem.key).icon
                "
                alt="icon"
            /></span>
            <span class="menu-title">{{
              getMenuStaticData(aclItem.key).menu_name
            }}</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="collapse">
            <ul class="nav flex-column sub-menu">
              <ng-container
                *ngTemplateOutlet="
                  treeViewList;
                  context: { $implicit: aclItem.value }
                "
              >
              </ng-container>
              <ng-template #treeViewList let-list>
                <span *ngFor="let item of list">
                  <span *ngIf="item?.hasOwnProperty('children')">
                    <ng-container
                      *ngTemplateOutlet="
                        treeViewListNested;
                        context: { $implicit: item }
                      "
                    >
                    </ng-container>
                  </span>
                  <span *ngIf="!item?.hasOwnProperty('children')">
                    <li
                      *ngIf="getMenuStaticData(item.submenu_id).is_active"
                      class="nav-item cursor"
                      [id]="getMenuStaticData(item.submenu_id).routerId"
                    >
                      <a
                        class="nav-link singleMenu"
                        [routerLink]="[
                          getMenuStaticData(item.submenu_id).routerUrl
                        ]"
                        routerLinkActive="active"
                        #Class="routerLinkActive"
                        (click)="menuClick(getMenuStaticData(item.submenu_id))"
                      >
                        <span
                          ><img
                            class="menuIcon"
                            [src]="
                              '../../../../assets/images/menu-icons/' +
                              getMenuStaticData(item.submenu_id).icon
                            "
                            alt="icon"
                        /></span>
                        {{ item.submenu_name }}
                      </a>
                    </li>
                  </span>
                </span>
              </ng-template>
            </ul>

            <!-- This template is for Nested Menu -->
            <ng-template #treeViewListNested let-list>
              <li class="nav-item cursor">
                <a
                  class="nav-link collapsible pl-0"
                  style="padding: 0.7rem 1rem !important"
                >
                  <span
                    ><img
                      class="menuIcon"
                      [src]="
                        '../../../../assets/images/menu-icons/' +
                        getMenuStaticData(list.submenu_id).icon
                      "
                      alt="icon"
                  /></span>
                  <span class="menu-title">{{ list.submenu_name }}</span>
                  <i class="menu-arrow"></i>
                </a>
                <div class="collapse" *ngIf="list?.hasOwnProperty('children')">
                  <!-- If any Childen found then call treeViewlist template -->
                  <ul class="nav flex-column sub-menu">
                    <ng-container
                      *ngTemplateOutlet="
                        treeViewList;
                        context: { $implicit: list.children }
                      "
                    >
                    </ng-container>
                  </ul>
                </div>
              </li>
            </ng-template>
          </div>
        </li>
      </span>
    </span>
  </ul>
</div>
</nav>
