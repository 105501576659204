<div class="container-scroller">
  <!-- Header -->
  <app-dashboard-header></app-dashboard-header>
  <!-- Header -->

  <div class="container-fluid page-body-wrapper">
    <!-- Theam Color change Panal -->

    <!-- Dashboard Sidebar Menu -->
    <app-dashboard-menu></app-dashboard-menu>

    <!-- Dashboard Sidebar Menu -->
    <div
      class="main-panel sidebar-full-main-panel-margin"
      id="main-panel-control"
    >
      <div style="flex: 1">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <!-- Footer-->
  <app-footer></app-footer>
  <!-- Footer -->
</div>
