import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { initializeApp } from 'firebase/app';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddPermissionGuard } from './guards/addPermission.guard';
import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from './guards/auth.guard';
import { EditPermissionGuard } from './guards/editPermission.guard';
import { GetPermissionGuard } from './guards/getPermission.guard';
import { MenuGuard } from './guards/menu.guard';
import { DashboardHeaderComponent } from './layout/dashboard-layout/dashboard-header/dashboard-header.component';
import { DashboardLayoutComponent } from './layout/dashboard-layout/dashboard-layout/dashboard-layout.component';
import { DashboardMenuComponent } from './layout/dashboard-layout/dashboard-menu/dashboard-menu.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { TokenInterceptor } from './shared/interceptor/token/token.interceptor';
import { DevExtremeModule } from './shared/modules/devExtreme.module';
import { SharedModule } from './shared/modules/share.module';
import { HttpRequestService } from './shared/services/http-request/http-request.service';
import { NotificationMainComponent } from './modules/dashboard/notification/notification-main/notification-main.component';

initializeApp(environment.firebase);

@NgModule({
  declarations: [
    AppComponent,
    DashboardLayoutComponent,
    DashboardHeaderComponent,
    DashboardMenuComponent,
    NotFoundComponent,
    NotificationMainComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    DevExtremeModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    SharedModule,
  ],
  providers: [
    CookieService,
    HttpRequestService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    AuthGuard,
    MenuGuard,
    AdminGuard,
    AddPermissionGuard,
    EditPermissionGuard,
    GetPermissionGuard,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
