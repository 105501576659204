import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpRequestService } from '../http-request/http-request.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as CryptoJS from 'crypto-js';
const jwtHelper = new JwtHelperService();
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  secretKey = 'bracWashProject2023#DevelopedBy@BMQA@2023_TonmoyrUdra';

  constructor(private cookieService: CookieService) {}

  encrypt(value: string): string {
    //return value;
    if (environment.envName == 'development') {
      return value;
    } else {
      return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
    }
  }

  decrypt(textToDecrypt: string) {
    if (environment.envName == 'development') {
      return textToDecrypt;
    } else {
      if (textToDecrypt == null) {
        return textToDecrypt;
      } else {
        return CryptoJS.AES.decrypt(
          textToDecrypt,
          this.secretKey.trim(),
        ).toString(CryptoJS.enc.Utf8);
      }
    }
  }

  setProjectInLocalStorage(programId: any) {
    localStorage.setItem(
      'current_selected_program',
      this.encrypt(JSON.stringify(programId)),
    );
  }

  getProjectFromLocalStorage() {
    return JSON.parse(
      this.decrypt(localStorage.getItem('current_selected_program')) as string,
    );
  }

  getToken() {
    // return this.cookieService.get('token');
    return localStorage.getItem('token');
  }

  setToken(token: string) {
    //this.cookieService.set('token', token);
    //this.cookieService.set('token2', token, null, '../',);
    localStorage.setItem('token', token);
  }

  setSessionUserFromToken(token: string) {
    try {
      const tokenDecode = jwtHelper.decodeToken(token);
      delete tokenDecode.iat;
      delete tokenDecode.iss;
      localStorage.setItem('sessionUser', JSON.stringify(tokenDecode));
      return true;
    } catch (error) {
      return false;
    }
  }

  setSessionUser(user: any) {
    localStorage.setItem('sessionUser', this.encrypt(JSON.stringify(user)));
  }

  getSessionUser() {
    return JSON.parse(this.decrypt(localStorage.getItem('sessionUser')));
  }

  setBracToken(token: string) {
    localStorage.setItem('multipass', JSON.stringify(token));
  }

  getBracToken() {
    return JSON.parse(localStorage.getItem('multipass')!);
  }

  getUserInfo() {
    return JSON.parse(localStorage.getItem('user')!);
  }

  setUserInfo(user: string) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  getACLMenuInfo() {
    return JSON.parse(this.decrypt(localStorage.getItem('acl')));
  }

  setACLMenuInfo(data: any) {
    localStorage.setItem('acl', this.encrypt(JSON.stringify(data)));
  }

  getControls() {
    return JSON.parse(this.decrypt(localStorage.getItem('controls')));
  }

  setControls(data: any) {
    localStorage.setItem('controls', this.encrypt(JSON.stringify(data)));
  }

  getSections() {
    return JSON.parse(this.decrypt(localStorage.getItem('sections')));
  }

  setSection(data: any) {
    localStorage.setItem('sections', this.encrypt(JSON.stringify(data)));
  }

  getSelectedMenuInfo() {
    return JSON.parse(this.decrypt(localStorage.getItem('selectedMenu')));
  }

  setSelectedMenuInfo(data: any) {
    localStorage.setItem('selectedMenu', this.encrypt(JSON.stringify(data)));
  }

  setLocalStorage(key: string, data: any){
    localStorage.setItem(key, this.encrypt(JSON.stringify(data)));
  }
  getLocalStorage(key:string) {
    return JSON.parse(this.decrypt(localStorage.getItem(key)));
  }

  clearLocalStorageByKey(key: string){
    localStorage.removeItem(key);
  }
}
