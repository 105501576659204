import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectService } from '../../services/project/project.service';
import { UtilitiesService } from '../../services/utilities/utilities.service';
import { ValidationRule } from 'devextreme/common';
import { ReportService } from '../../services/report/report.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { ReportSharedService } from '../../shared-services/report-shared/report-shared-service.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css'],
})
export class ProjectComponent implements OnInit {
  @Input() isEditing = false;

  @Input() text: string;

  @Input() validators: ValidationRule[];

  @Input() validationGroupName: string;

  @Input() value!: string;

  @Output() valueChange = new EventEmitter<string>();

  valueChanged(e) {
    // console.log(e);
    this.value = e?.selectedItem?.id;
    this.valueChange.emit(e.selectedItem);
    this.sharedService.instruct(e?.selectedItem ? '1' : '0');
  }

  projectList: any[];

  constructor(
    public projectService: ProjectService,
    public utilitiesService: UtilitiesService,
    public reportService: ReportService,
    public localStorageService: LocalStorageService,
    private sharedService: ReportSharedService,
  ) {}

  ngOnInit() {
    this.getProjectList();
  }

  private getProjectList(): void {
    const sessionUser = this.localStorageService.getSessionUser();
    if (sessionUser.name === 'Admin') {
      this.projectService.getProjectList().subscribe((result) => {
        this.projectList = this.utilitiesService.getFiltered(
          result,
          'is_active',
          true,
        );
      });
    } else {
      this.projectList = sessionUser.program_project.map((project) => ({
        ...project,
        id: project.project_id,
      }));
    }
  }

  // projectOptionChange(e) {
  //   if (e.selectedItem) {
  //     this.project_id = e.selectedItem.id;
  //     this.project_name = e.selectedItem.project_name;

  //     if (this.project_id) {
  //       this.getBeneficiaryList(this.project_id);
  //       this.getFormListForCommon(this.project_id);
  //     }
  //   } else {
  //     this.beneficiarieList = [];
  //   }
  // }
}
