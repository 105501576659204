<nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
  <div
    class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center"
    style="background: #d10074"
  >
    <a
      class="navbar-brand brand-logo"
      style="
        text-align: left;
        width: -webkit-fill-available;
        padding-left: 5px;
        z-index: 2;
      "
    >
      <div>
        <img
          src="../../../../assets/images/BRAC-White-logo.png"
          class="mr-2"
          alt="logo"
        />
      </div>
      <div class="logoText">WATER, SANITATION AND HYGIENE PROGRAMME</div>
    </a>
    <a class="navbar-brand brand-logo-mini">
      <img
        style="position: relative;  z-index: 2;"
        src="../../../../assets/images/BRAC-White-logo-only.png"
        alt="logo"
      />
    </a>
  </div>
  <div
    class="navbar-menu-wrapper d-flex align-items-center justify-content-end"
    style="background: #d10074; z-index: 1"
  >
    <!-- <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
      <span class="icon-menu"></span>
    </button> -->
    <!-- style="color: white;position: relative; top: 46px;" -->
    <div (click)="toggleSidebar()">
      <button
        *ngIf="!showCross"
        class="navbar-toggler align-self-center menuToggleIcon toggleIcon"
        type="button"
      >
        <span class="icon-menu">
          <img
            src="../../../../assets/images/menu-icons/side_bar_2.svg"
            onmouseover="this.src='../../../../assets/images/menu-icons/side_bar.svg'"
            onmouseout="this.src='../../../../assets/images/menu-icons/side_bar_2.svg'"
            alt="logo"
          />
        </span>
      </button>
      <button
        *ngIf="showCross"
        class="navbar-toggler align-self-center menuToggleCrossIcon toggleIcon"
        type="button"
      >
        <span class="icon-cross">
          <img
            src="../../../../assets/images/menu-icons/side_bar_2.svg"
            onmouseover="this.src='../../../../assets/images/menu-icons/side_bar.svg'"
            onmouseout="this.src='../../../../assets/images/menu-icons/side_bar_2.svg'"
            alt="logo"
          />
        </span>
      </button>
    </div>

    <!-- <ul class="navbar-nav mr-lg-2">
      <li class="nav-item nav-search d-none d-lg-block">
        <div class="input-group">
          <div class="input-group-prepend hover-cursor" id="navbar-search-icon">
            <span class="projectName"> BRAC WASH</span>
          </div>
        </div>
      </li>
    </ul> -->
    <ul class="navbar-nav navbar-nav-right">
      <!-- Notification -->

      <li class="nav-item dropdown">
        <a
          class="nav-link count-indicator dropdown-toggle"
          id="notificationDropdown"
          href="#"
          data-toggle="dropdown"
        >
          <img
            class="bellIcon mx-0"
            src="../../../../assets/images/icon_gala_bell.svg"
            alt="notification"
          />

          <!-- <i class="dx-icon-bell bellIcon mx-0"></i> -->

          <span
            *ngIf="notificationService.notificationDot"
            class="count"
          ></span>
        </a>
        <div
          class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
          style="width: 500px; height: 400px; overflow-y: auto"
          aria-labelledby="notificationDropdown"
        >
          <app-notification-main></app-notification-main>
        </div>
      </li>
      <!-- Profile -->
      <li class="nav-item nav-profile dropdown">
        <a
          class="nav-link dropdown-toggle"
          href="#"
          data-toggle="dropdown"
          id="profileDropdown"
          (click)="makeUserProfile()"
        >
          <!-- <img src="../../../../assets/images/face28.jpg" alt="profile"/> -->
          <!-- <div class="profilePicWithText">{{profileName}}</div> -->
          <div class="rightTopProfileMenu">
            <i class="dx-icon-user userIcon"></i>
            <span>{{ sessionUser?.name }} {{sessionUser?.role?.role_ids?.length > 1 ? '' : '(' + sessionUser?.role?.short_form + ')' }} </span>
            <i class="dx-icon-spindown spinIcon"></i>
          </div>
        </a>
        <div
          class="dropdown-menu dropdown-menu-right navbar-dropdown"
          aria-labelledby="profileDropdown"
        >
          <div class="proName">PIN: {{ sessionUser?.pin }}</div>
          <!-- <div class="proName">({{ sessionUser?.role?.short_form }})</div> -->
          <!-- <a class="dropdown-item" (click)="showProfile()">
            <i class="ti-user text-primary"></i>
            Profile
          </a> -->
          <a
            class="dropdown-item"
            [routerLink]="['../dashboard/change-password']"
          >
            <i class="ti-key text-primary"></i>
            Change password
          </a>
          <a
            class="dropdown-item"
            (click)="showProgramPopUp()"
            *ngIf="!isAdmin"
          >
            <i class="ti-receipt text-primary"></i>
            Change Project
          </a>
          <a class="dropdown-item" (click)="logout()">
            <i class="ti-power-off text-primary"></i>
            Logout
          </a>
        </div>
      </li>
    </ul>
    <button
      class="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
      type="button"
      data-toggle="offcanvas"
    >
      <span class="icon-menu"
        ><i class="dx-icon-menu" style="color: white"></i
      ></span>
    </button>
  </div>
</nav>
<a id="backToTopBtn" title="Back to top"></a>

<dx-popup
  *ngIf="popupVisible_program"
  [showTitle]="true"
  title="Select Project"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  width="500"
  height="200"
  [(visible)]="popupVisible_program"
  [shading]="true"
  [showCloseButton]="true"
  shadingColor="rgba(102, 102, 102, 0.5)"
>
  <!-- <dxi-toolbar-item
    widget="dxButton"
    toolbar="top"
    location="after"
    [options]="closeButtonOptions"
  >
  </dxi-toolbar-item> -->
  <dxo-position at="center" my="center" of="window" collision="fit">
  </dxo-position>
  <div *dxTemplate="let data of 'content'">
    <app-programme
      [userInfo]="sessionUser"
      (closePopUpCLick)="closeProgramPopUp()"
    ></app-programme>
  </div>
</dx-popup>
