import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../shared/services/auth/auth.service';
import staticMenuData from '../layout/dashboard-layout/dashboard-menu/staticData.json';
import { LocalStorageService } from '../shared/services/local-storage/local-storage.service';

@Injectable()
export class AddPermissionGuard implements CanActivate {
  staticMenuData: any = [];
  selectedMenu: any;
  constructor(
    private router: Router,
    public authService: AuthService,
    public localStorageService: LocalStorageService,
  ) {
    this.staticMenuData = staticMenuData;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //  // split after 2nd slash;
    // let url = state.url.replace(/(?:.*?\/){2}/, '');
    // // remove slash from string
    // url = url.split('/').join('')

    // // Find index from staticMenu.JSON with include from URL value
    // var index : any;
    // this.staticMenuData.some(function(entry : any, i: any): any {
    //   if(entry.routerUrl != ""){
    //     const u = entry.routerUrl.split('/').join(''); // Remove slash from url.
    //     const newUrlRegx = new RegExp(u); // Make a regular expresion  with that url
    //     if (url.match(newUrlRegx)) {
    //       index = i;
    //       return true
    //     }
    //   }
    // });
    // if(index != -1){
    //   this.selectedMenu = this.staticMenuData[index];
    // }else{
    //   this.selectedMenu  = null;
    //   console.log("No Menu Found From static page");
    // }

    // // Get my menu from ACL local storage
    // const aclMenu = this.localStorageService.getACLMenuInfo();
    // // find  selected menu is on the My acl menu list or not.
    // if(this.selectedMenu){
    //   let index = aclMenu.findIndex((x: any) => x.submenu_id ==  this.selectedMenu.menu_id)
    //   if(index != -1){
    //     //FOund menu
    //     console.log('Menu Selected from Menu Guard', aclMenu[index]);

    //     if(aclMenu[index].actions.create ==true){
    //       return true;
    //     } else {
    //       this.router.navigateByUrl('/dashboard');
    //       return false;
    //     }
    //   }else{
    //     this.router.navigateByUrl('/dashboard');
    //     return false;
    //   }
    // } else{
    //   this.router.navigateByUrl('/dashboard');
    //   return false;
    // }

    let selectedMenu = this.localStorageService.getSelectedMenuInfo();
    // w_sm81 = Access Control Management
    // For admin can access this menu alltime. if ACM control ki block also.
    if (selectedMenu.submenu_id == 'w_sm81' && this.authService.isAdminRole()) {
      return true;
    }
    if (selectedMenu) {
      if (selectedMenu?.actions?.create) {
        return true;
      } else {
        this.router.navigateByUrl('/dashboard');
        return false;
      }
    } else {
      this.router.navigateByUrl('/dashboard');
      return false;
    }
  }
}
