import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { LocalStorageService } from '../../../shared/services/local-storage/local-storage.service';
import { NotificationService } from '../../../shared/services/notification/notification.service';
@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.css'],
})
export class DashboardHeaderComponent implements OnInit {
  sessionUser: any;
  profileName: any = {};
  showCross: boolean = false;
  isProfilePopUpVisible: boolean = false;
  message: any = null;
  popupVisible_program: boolean;
  closeButtonOptions: { text: string; onClick(e: any): void };
  isAdmin: boolean;
  userInfo: any;
  submitButtonOptions: { text: string; onClick(e: any): void };

  constructor(
    private authService: AuthService,
    private router: Router,
    public localStorageService: LocalStorageService,
    public readonly notificationService: NotificationService,
  ) {
    const that = this;
    this.closeButtonOptions = {
      text: 'X',
      onClick(e) {
        that.closeProgramPopUp(that);
      },
    };
  }

  ngOnInit(): void {
    this.backToTop();
    this.sessionUser = this.localStorageService.getSessionUser();
    this.makeUserProfile();
    this.isAdmin = this.authService.isAdminRole();

    $('[data-toggle="offcanvas"]').on('click', function () {
      $('.sidebar-offcanvas').toggleClass('active');
    });
  }

  makeUserProfile() {
    this.sessionUser = this.localStorageService.getSessionUser();
    this.sessionUser.shortNameFromEmail = this.sessionUser.email
      .substring(0, 2)
      .toUpperCase();

    //console.log(this.profileName);
  }
  logout() {
    const res = this.authService.logout();
    if (res) {
      //this.authService.setUserDataOnLocalStorageObs(false);
      //console.log('logout success');
      this.router.navigateByUrl('/auth');
    }
  }

  showProfile() {
    this.isProfilePopUpVisible = true;
  }

  toggleSidebar() {
    var body = $('body');
    this.showCross = this.showCross == false ? true : false;
    if (
      body.hasClass('sidebar-toggle-display') ||
      body.hasClass('sidebar-absolute')
    ) {
      body.toggleClass('sidebar-hidden');
      // this.showCross = false;
    } else {
      body.toggleClass('sidebar-icon-only');
      // this.showCross = true;
    }
    body.toggleClass('toggleCustom');
    //this.specialReportTableUIHandler();

    const mainPanel: any = document.getElementById('main-panel-control');
    if (this.showCross) {
      mainPanel.classList.add('sidebar-icon-only-main-panel-margin');
      mainPanel.classList.remove('sidebar-full-main-panel-margin');
      setTimeout(() => {
        let crossIcon: any = document.getElementsByClassName(
          'menuToggleCrossIcon',
        )[0];
        crossIcon.style.left = '-203px';
      }, 100);
    } else {
      mainPanel.classList.add('sidebar-full-main-panel-margin');
      mainPanel.classList.remove('sidebar-icon-only-main-panel-margin');
    }
  }

  // specialReportTableUIHandler() {
  //   const specialReportHeaderId = document.getElementById(
  //     'specialReportHeaderId'
  //   );
  //   if (!!specialReportHeaderId && this.showCross) {
  //     specialReportHeaderId.style.setProperty('width', '0px');
  //   }
  //   if (!!specialReportHeaderId && !this.showCross) {
  //     specialReportHeaderId.style.removeProperty('width');
  //   }
  // }

  backToTop() {
    $(function () {
      var btn = $('#backToTopBtn');

      $(window).scroll(function () {
        if ($(window).scrollTop() > 300) {
          btn.addClass('show');
        } else {
          btn.removeClass('show');
        }
      });

      btn.on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({ scrollTop: 0 }, '300');
      });
    });
  }

  showProgramPopUp() {
    this.popupVisible_program = true;
  }
  closeProgramPopUp(that?) {
    if (that) {
      that.popupVisible_program = false;
    } else {
      this.popupVisible_program = false;
    }
    // this.autenticationService.logout();
  }

  // public onNotificationOpen() {
  //   this.notificationService.setNotificationDot(false);
  // }
}
