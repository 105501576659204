<p class="mb-0 font-weight-normal" style="padding: 0.5rem 1.5rem">
  Notifications
</p>
<a
  class="notification-dropdown-item preview-item"
  *ngFor="let item of notificationList"
  (click)="updateIsSeen(item)"
>
  <div class="preview-thumbnail">
    <div
      class="preview-icon"
      [ngClass]="!item?.is_seen ? 'bg-success' : 'bg-info'"
    >
      <i class="ti-info-alt mx-0"></i>
    </div>
  </div>
  <div class="preview-item-content">
    <h6 class="preview-subject font-weight-normal">{{ item.data.title }}</h6>
    <p class="font-weight-light small-text mb-0 text-muted">
      {{ item.created_at | date: "dd-MMM-yyyy hh:mm a" }}
    </p>
  </div>
</a>
<!-- <a class="dropdown-item preview-item">
  <div class="preview-thumbnail">
    <div class="preview-icon bg-warning">
      <i class="ti-settings mx-0"></i>
    </div>
  </div>
  <div class="preview-item-content">
    <h6 class="preview-subject font-weight-normal">Settings</h6>
    <p class="font-weight-light small-text mb-0 text-muted">
      Private message
    </p>
  </div>
</a>
<a class="dropdown-item preview-item">
  <div class="preview-thumbnail">
    <div class="preview-icon bg-info">
      <i class="ti-user mx-0"></i>
    </div>
  </div>
  <div class="preview-item-content">
    <h6 class="preview-subject font-weight-normal">New user registration</h6>
    <p class="font-weight-light small-text mb-0 text-muted">
      2 days ago
    </p>
  </div>
</a> -->
