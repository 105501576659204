import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  getcurrentYear: number;
  constructor() {}

  ngOnInit(): void {
    this.getcurrentYear = new Date().getFullYear();
    this.footerIntersectionViewPort();
  }

  footerIntersectionViewPort() {
    // Function to handle footer visibility changes
    function handleFooterVisibility(entries) {
      const menu = document.getElementById('sidebar');

      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Footer is in the viewport, adjust the menu height
          menu.style.minHeight = 'calc(100vh - 107px)'; // Adjust the height as needed
        } else {
          // Footer is out of the viewport, reset the menu height
          menu.style.minHeight = 'calc(100vh - 60px)'; // Reset to the initial height
        }
        
      });
    }

    // Create an Intersection Observer instance
    const observer = new IntersectionObserver(handleFooterVisibility, {
      root: null, // Use the viewport as the root
      rootMargin: '0px', // No margin
      threshold: 0.5, // Trigger when at least 50% of the footer is visible
    });

    // Observe the footer
    const footer = document.getElementById('footer');
    observer.observe(footer);
  }
}
