export const environmentCommon = {
  apiURL: 'http://common.com',
  beneficiaryInfoFormId: '',
  firebase: {
    //databaseURL: "config data from general tab",
    apiKey: 'AIzaSyB0TAJi5gYHdFp-SuT5s0gnJ-0VHrIOZhA',
    authDomain: 'brac-upg-application.firebaseapp.com',
    projectId: 'brac-upg-application',
    storageBucket: 'brac-upg-application.appspot.com',
    messagingSenderId: '314523813373',
    appId: '1:314523813373:web:c630e68c92df8032218594',
    measurementId: 'G-36GX2DPP60',
    vapidKey:
      'BD4vpOQbmbnllm01dghCycGUNykBpPXQXR18I9HbpDquK_Fz1XU3mbd7T1QQkFOufkoI24HJwWQh5EJVIa0FWSs', //"fron generated key crom Cloud Messaging"
  },
};
