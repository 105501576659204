import { Injectable } from '@angular/core';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { Observable, map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Url } from '../../enum/url.enum';
import { HttpRequestService } from '../http-request/http-request.service';
import { UtilitiesService } from '../utilities/utilities.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notificationDot: boolean = false;
  unseenNotificationsCount: number = 0;
  message: any = null;

  constructor(
    private readonly httpService: HttpRequestService,
    private readonly utilitiesService: UtilitiesService,
  ) {}

  public setNotificationDot(value: boolean) {
    this.notificationDot = value;
  }

  public setUnseenNotificationsCount(operation: 'plus' | 'minus') {
    operation === 'plus'
      ? this.unseenNotificationsCount++
      : this.unseenNotificationsCount--;

    this.unseenNotificationsCount > 0
      ? this.setNotificationDot(true)
      : this.setNotificationDot(false);
  }

  public requestPermissionToFirebaseServer() {
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: environment.firebase.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          console.log('Hurraaa!!! we got the token.....');
          console.log(currentToken);
        } else {
          console.log(
            'No registration token available. Request permission to generate one.',
          );
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  }

  public listenFromFireBaseServer() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      this.message = payload;
    });
  }

  public registerNotification(body): Observable<any> {
    return this.httpService
      .post(Url.NOTIFICATION + '/notifications/register', body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public unregisterNotification(body): Observable<any> {
    return this.httpService
      .post(Url.NOTIFICATION + '/notifications/unregister', body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getAllNotificationByUser(userId: string): Observable<any> {
    return this.httpService
      .get(Url.NOTIFICATION + '/notifications/get-all-by-user/' + userId)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public pushNotification(body): Observable<any> {
    return this.httpService
      .post(Url.NOTIFICATION + '/notifications/push', body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public updateIsSeen(userId: string, notificationId: string) {
    return this.httpService
      .patch(
        Url.NOTIFICATION +
          '/notifications/users/' +
          userId +
          '/messages/' +
          notificationId +
          '/update-is-seen',
        {},
      )
      .pipe(map((x: any) => x));
  }
}
