import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { UtilitiesService } from './../utilities/utilities.service';
import { HttpRequestService } from '../http-request/http-request.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { Url } from '../../enum/url.enum';
const jwtHelper = new JwtHelperService();

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private router: Router,
    private http: HttpRequestService,
    private cookieService: CookieService,
    private utilitiesService: UtilitiesService,
    private httpRequestService: HttpRequestService,
    private localStorageService: LocalStorageService,
  ) {}
  private storeUserDataOnLocalStorageObs$: BehaviorSubject<any> =
    new BehaviorSubject(null);

  isLoggedIn() {
    const token = this.localStorageService.getToken();
    if (token) {
      try {
        if (jwtHelper.isTokenExpired(token)) {
          // true means token expired.
          this.logout();
          this.router.navigateByUrl('/auth');
          return false;
        } else {
          return true;
        }
      } catch (error) {
        console.log(error);
        this.logout();
        this.router.navigateByUrl('/auth');
        return false;
      }
    } else {
      return false;
    }
  }

  logout() {
    localStorage.clear();
    this.cookieService.deleteAll('../');
    return true;
  }

  signIn(username: string, password: string): Observable<any> {
    // console.log('<========Token 0Auth wtih (grant_type: password) service called========>');
    // const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const body = { email: username, password };
    return this.http.post(Url.AUTH + '/account/login', body);
  }

  public changePassword(body: any): Observable<any> {
    return this.httpRequestService
      .patch(Url.AUTH + '/account/resetPassword', body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  isAdminRole() {
    const sUser = this.localStorageService.getSessionUser();
    if (sUser?.role?.short_form.toLowerCase() === 'admin') {
      return true;
    } else {
      return false;
    }
  }

  isSMORole() {
    const sUser = this.localStorageService.getSessionUser();
    if (sUser?.role?.short_form.toLowerCase() == 'smo') {
      return true;
    } else {
      return false;
    }
  }
  getStoreUserDataOnLocalStorageObs(): Observable<any> {
    return this.storeUserDataOnLocalStorageObs$.asObservable();
  }

  setUserDataOnLocalStorageObs(data) {
    this.storeUserDataOnLocalStorageObs$.next(data);
  }
  // getToken(name: any) {
  //   var nameEQ = name + "=";
  //   var cookies = document.cookie.split(';');
  //   for (var i = 0; i < cookies.length; i++) {
  //     var cookie = cookies[i];
  //     while (cookie.charAt(0) == ' ') cookie = cookie.substring(1, cookie.length);
  //     if (cookie.indexOf(nameEQ) == 0) return cookie.substring(nameEQ.length, cookie.length);
  //   }
  //   return null;
  //   // return localStorage.getItem('token')
  // }

  // setToken(name: any, value: any, days: any) {
  //   // localStorage.setItem('token',value)

  //     var expires = "";
  //     if (days) {
  //       var date = new Date();
  //       date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  //       expires = "; expires=" + date.toUTCString();
  //     }
  //     document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  // }
}
