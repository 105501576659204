<div class="row">
  <div class="col-sm-12">
    <label
      class="col-form-label"
      [ngClass]="{ requiredMark: validators.length > 0 }"
      >Select Activity:</label
    >
    <div>
      <dx-drop-down-box
        [(value)]="treeBoxValue"
        [inputAttr]="{ 'aria-label': 'Owner' }"
        valueExpr="id"
        displayExpr="name"
        placeholder="Select a value..."
        [showClearButton]="true"
        [dataSource]="flatData"
        (onValueChanged)="syncTreeViewSelection($event)"
      >
        <div *dxTemplate="let data of 'content'">
          <dx-tree-view
            [dataSource]="flatData"
            keyExpr="id"
            displayExpr="name"
            parentIdExpr="parent_id"
            dataStructure="plain"
            ScrollDirection="horizontal"
            [selectNodesRecursive]="selectNodesRecursive"
            collapseIcon="minus"
            selectionMode="multiple"
            [showCheckBoxesMode]="'normal'"
            [selectByClick]="true"
            (onContentReady)="treeViewContentReady($event)"
            (onItemSelectionChanged)="treeViewSelectionChanged($event)"
          >
            <div
              [ngClass]="[
                treeNode?.type === 'Category' ? 'cat' : '',
                treeNode?.type === 'Sub-Category' ? 'sub-cat' : '',
                treeNode?.type === 'Activity' ? 'act' : ''
              ]"
              *dxTemplate="let treeNode of 'item'"
            >
              <div class="d-flex justify-content-between align-items-center">
                <div class="">
                  <span class="align-items-center">
                    {{ treeNode?.name }}
                  </span>
                </div>
              </div>
            </div>
          </dx-tree-view>
        </div>
        <dx-validator
          [validationGroup]="validationGroupName"
          [validationRules]="validators"
        ></dx-validator>
      </dx-drop-down-box>
    </div>
  </div>
</div>
