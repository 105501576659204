import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Url } from '../../enum/url.enum';
import { UtilitiesService } from '../utilities/utilities.service';
import { HttpRequestService } from '../http-request/http-request.service';
import { StaffMappingService } from './../staff-mapping/staff-mapping.service';
import { ProjectActivityModel } from '../../model/project-activity/project-activity.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectActivityMappingService {
  constructor(
    private readonly httpService: HttpRequestService,
    private staffMappingService: StaffMappingService,
    private readonly utilitiesService: UtilitiesService,
  ) {}

  projectActivityMappingAPI = Url.CONFIGURATION + '/project-activity-mapping';

  public getProjectActivityMapping(): Observable<any> {
    return this.httpService.get(this.projectActivityMappingAPI + '/all').pipe(
      map((x: any) => this.utilitiesService.responseHandler(x)),
      map((x: any) =>
        x.sort(this.dynamicSortWithDateForProjectActivity('desc')),
      ),
      map((x: any) => this.utilitiesService.getSlNo(x)),
      map((x: any) =>
        this.utilitiesService.addCustomProperty(
          x,
          'ProjectActivityMappingList',
        ),
      ),
    );
  }

  private dynamicSortWithDateForProjectActivity(sortOrderType = 'asc') {
    var sortOrder = sortOrderType.toLowerCase() === 'desc' ? -1 : 1;

    return function (a, b) {
      var dateA = new Date(a?.checklist?.created_at);
      var dateB = new Date(b?.checklist?.created_at);

      if (dateA < dateB) return -1 * sortOrder;
      if (dateA > dateB) return 1 * sortOrder;
      return 0;
    };
  }

  public createProjectActivityMapping(
    body: ProjectActivityModel,
  ): Observable<any> {
    return this.httpService
      .post(this.projectActivityMappingAPI + '/create', body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public deleteProjectActivityMapping(
    projectId: string,
    activityId: string,
  ): Observable<any> {
    return this.httpService
      .delete(
        this.projectActivityMappingAPI +
          '/delete?project_id=' +
          projectId +
          '&activity_id=' +
          activityId,
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getProjectActivityMappingByProjectAndActivity(
    projectId: string,
    activityId: string,
  ): Observable<any> {
    return this.httpService
      .get(
        this.projectActivityMappingAPI +
          '/details?project_id=' +
          projectId +
          '&activity_id=' +
          activityId,
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public updateProjectActivityMapping(body): Observable<any> {
    return this.httpService
      .patch(this.projectActivityMappingAPI + '/update', body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public changeStatusProjectActivity(
    project_id: string,
    activity_id: string,
    body: { is_active: boolean },
  ): Observable<any> {
    return this.httpService
      .patch(
        this.projectActivityMappingAPI +
          '/update-status?project_id=' +
          project_id +
          '&activity_id=' +
          activity_id,
        body,
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getVerifiedByAndApprovedByStaffs(
    projectId: string,
    roleId: string,
    userFilterType: 'APPROVER' | 'VERIFIER',
  ): Observable<any[]> {
    return this.staffMappingService.getVerifiedByAndApprovedByStaffs(
      projectId,
      roleId,
      userFilterType,
    );
  }

  public getStaffListByProjectId(
    projectId: string,
  ): Observable<{ staffList: any[]; modifiedStaffList: any[] }> {
    let staffList: any[] = [];
    let modifiedStaffList: any[] = [];
    return this.staffMappingService.getStaffMappingList(projectId).pipe(
      map((result) => {
        staffList = JSON.parse(
          JSON.stringify(
            result.map((staff) => ({
              ...staff,
              name_pin: staff.name + '- ' +staff.role_short_form + '- ' + staff.pin,
            })),
          ),
        );
        modifiedStaffList = this.modifyStaffList(result);
        return { staffList, modifiedStaffList };
      }),
    );
  }

  private modifyStaffList(staffList: any[]): any[] {
    return staffList.map((item) => {
      item.selected = false;
      item.edit = false;
      item.view = true;
      item.name2 = item.name;
      const area = item.districts
        ? item.divisions
          ? item.districts + '>' + item.divisions
          : item.districts
        : item.divisions;
      item.name = item.name + '- ' + item.role_name + ', ' + area;
      return item;
    });
  }

  public modProjectActivityForStatusChange(activity: any) {
    return {};
  }

  public getActivityMappingListByProject(projectId: string): Observable<any> {
    return this.httpService
      .get(
        this.projectActivityMappingAPI +
          '/activity-details-by-project/' +
          projectId,
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
}
