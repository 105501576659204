import { Injectable } from '@angular/core';
import { Url } from '../../enum/url.enum';
import { HttpClient } from '@angular/common/http';
import { HttpRequestService } from '../http-request/http-request.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AclService {
  roleAPI = Url.AUTH + '/roles';
  aclAPI = Url.AUTH + '/acl';

  constructor(private httpService: HttpRequestService) {}
  // ACL

  getAllACLByRoleId(projectId: string, roleId: string = null) {
    //let url: any = this.roleAPI + '/' + roleId + '/acl/project/' + projectId;
    let url: any = this.aclAPI + '/project/' + projectId;
    if (roleId) {
      url = url + '?roleId=' + roleId;
    }
    return this.httpService.get(url);
  } 

  getAllACLByRoleIds(projectId: string, roleIds: string, forAdmin: boolean = false) { 
    let url: any;
    if(forAdmin){
      url = this.aclAPI + '/project/' + projectId; 
    } else {
      url = Url.AUTH + '/acm-by-project-roles?project_id=' + projectId + '&role_ids=' + roleIds;  
    }
    return this.httpService.get(url);
  }

  getAllMenu() {
    return this.httpService.get(this.aclAPI + '/menu');
  }

  getAllSubMenu(menuId: string) {
    return this.httpService.get(this.aclAPI + '/menu/' + menuId + '/submenu');
  }

  createAcl(roleId: string, body: any): Observable<any> {
    return this.httpService.post(this.roleAPI + '/' + roleId + '/acl', body);
  }

  editAcl(aclID: string, roleId: string, body: any): Observable<any> {
    return this.httpService.put(
      this.roleAPI + '/' + roleId + '/acl/' + aclID,
      body,
    );
  }

  deleteAcl(aclID: string, roleId: string): Observable<any> {
    return this.httpService.delete(
      this.roleAPI + '/' + roleId + '/acl/' + aclID,
    );
  }
}
