import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../shared/services/auth/auth.service';
import staticMenuData from '../layout/dashboard-layout/dashboard-menu/staticData.json';
import { LocalStorageService } from '../shared/services/local-storage/local-storage.service';

@Injectable()
export class MenuGuard implements CanActivate {
  staticMenuData: any = [];
  selectedMenu: any;
  constructor(
    private router: Router,
    public authService: AuthService,
    public localStorageService: LocalStorageService,
  ) {
    this.staticMenuData = staticMenuData;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //return true;

    // var reg = new RegExp(/ + url + /, 'g')

    // url = url.split('/').join('')
    // let index: any = this.staticMenuData.findIndex(x=> {
    //   const u = x.routerUrl.split('/').join('');
    //   const newUrlRegx = new RegExp(u);
    //   return newUrlRegx.test(url)
    // });
    //let index: any = this.staticMenuData.findIndex(x => x.routerUrl.includes(url));
    // let index: any = this.staticMenuData.findIndex(x => url.indexOf(x.routerUrl) >= 0);

    // let data = this.staticMenuData.forEach(element => {
    //   const newUrlRegx = new RegExp(element.routerUrl.split('/').join(''));
    //   if(newUrlRegx.test(url)){
    //     return element;
    //   }
    // });

    // split after 2nd slash;
    let url = state.url.replace(/(?:.*?\/){2}/, ''); // dashboard/acm-management/access-control
    // remove slash from string
    url = url.split('/').join(''); // dashboardacm-managementaccess-control

    // Find index from staticMenu.JSON with include from URL value
    var index: any;
    this.staticMenuData.some(function (entry: any, i: any): any {
      if (entry.routerUrl != '') {
        const u = entry.routerUrl.split('/').join(''); // Remove slash from url. // acm-managementaccess-control
        const newUrlRegx = new RegExp(u); // Make a regular expresion  with that url
        if (url.match(newUrlRegx)) {
          index = i;
          return true;
        }
      }
    });
    if (index != -1) {
      this.selectedMenu = this.staticMenuData[index];
    } else {
      this.selectedMenu = null;
      console.log('No Menu Found From static page');
    }
    //    return true;
    // Get my menu from ACL local storage
    const aclMenu = this.localStorageService.getACLMenuInfo();
    // find  selected menu is on the My acl menu list or not.
    if (this.selectedMenu) {
      let index = aclMenu.findIndex(
        (x: any) => x.submenu_id == this.selectedMenu.menu_id,
      );
      if (index != -1) {
        //FOund menu
        //console.log('Menu Selected from Menu Guard', aclMenu[index]);
        this.localStorageService.setSelectedMenuInfo(aclMenu[index]);
        return true;
      } else {
        this.router.navigateByUrl('/dashboard');
        return false;
      }
    } else {
      this.router.navigateByUrl('/dashboard');
      return false;
    }
  }
}
