import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectService } from '../../services/project/project.service';
import { UtilitiesService } from '../../services/utilities/utilities.service';
import { CalendarZoomLevelEnum } from '../../enum/calendar-zoom-level.enum';
import { ValidationRule } from 'devextreme/common';

@Component({
  selector: 'app-month-year',
  templateUrl: './month-year.component.html',
  styleUrls: ['./month-year.component.css']
})
export class MonthYearComponent implements OnInit {

  @Input() isEditing = false;

  @Input() label: string;

  @Input() validators: ValidationRule[];  // If no validation need then send []

  @Input() validationGroupName: string;

  @Input() value: any;
  
  @Input() maxDate: Date = null;
  
  @Input() minDate: Date = null;
  
  @Input() viewLevel: string = CalendarZoomLevelEnum.YEAR;
  
  @Input() maxViewLevel: string = CalendarZoomLevelEnum.YEAR;

  @Output() valueChange = new EventEmitter<string>();

  valueChanged(e) {
    // console.log(e);
    this.valueChange.emit(e.value);
  }

  projectList: any[];

  constructor(
    public projectService: ProjectService,
    public utilitiesService: UtilitiesService,
  ) {}


  ngOnInit() {
  }

}
