<div class="container-scroller">
  <!-- Header -->
  <!-- <app-mobile-header></app-mobile-header> -->
  <!-- Header -->

  <div class="container-fluid page-body-wrapper" style="padding: 0px">
    <!-- Dashboard Sidebar Menu -->
    <!-- Dashboard Sidebar Menu -->
    <div class="main-panel" style="width: 100%">
      <router-outlet></router-outlet>
      <!-- Footer-->
      <!-- <app-footer></app-footer> -->
      <!-- Footer -->
    </div>
  </div>
</div>
