import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { Url } from '../../enum/url.enum';
import { HttpRequestService } from '../http-request/http-request.service';
import { UtilitiesService } from '../utilities/utilities.service';

@Injectable({
  providedIn: 'root',
})
export class MasterDataManagementService {
 
  masterDataAPI = Url.CONFIGURATION + '/masterdata';
  projectOfficeAPI = Url.PS + '/project-office'; 
  unionAPI = Url.PS + '/union';

  
  private webAclTriggerObs$: BehaviorSubject<any> = new BehaviorSubject(false);
  private geoItemAddObs$: BehaviorSubject<any> = new BehaviorSubject(false);

  constructor(
    private httpService: HttpRequestService,
    private utilitiesService: UtilitiesService,
  ) {}
 

  public getZone(): Observable<any> {
    return this.httpService.get(this.masterDataAPI + '/zone/allZone');
  }

  public getAllRegion(): Observable<any> {
    return this.httpService.get(this.masterDataAPI + '/region/allRegions');
  }

  public getBranch(): Observable<any> {
    return this.httpService.get(this.masterDataAPI + '/branch/allBranches');
  }

  public getProjectOfficeByCohortId(cohortId: any): Observable<any> {
    return this.httpService.get(this.projectOfficeAPI + '/' + cohortId);
  }

  public getwebACLTriggerObs(): Observable<any> {
    return this.webAclTriggerObs$.asObservable();
  }

  public setwebACLTriggerObs(data: any): void {
    this.webAclTriggerObs$.next(data);
  }

  public getGeoItemAddObs(): Observable<any> {
    return this.geoItemAddObs$.asObservable();
  }

  public setGeoItemAddObs(data: any): void {
    this.geoItemAddObs$.next(data);
  }
  // Division
  getDivisions(): Observable<any> {
    return this.httpService
      .get(this.masterDataAPI + '/division/all')
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  getDetailsDivision(id): Observable<any> {
    return this.httpService
      .get(this.masterDataAPI + '/division/details/'+ id)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  createDivision(body: any): Observable<any> {
    return this.httpService
      .post(this.masterDataAPI + '/division/add', body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  updateDivision(id, body: any): Observable<any> {
    return this.httpService
      .patch(this.masterDataAPI + '/division/update/' + id, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
  // End Division

// District
getDistricts(): Observable<any> {
  return this.httpService
    .get(this.masterDataAPI + '/district/all')
    .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
}

getDistrictsByDivision(division_id): Observable<any> {
  return this.httpService
    .get(this.masterDataAPI + '/district/all/division/'+ division_id)
    .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
}
getDetailsDistrict(division_id, id): Observable<any> {
  return this.httpService
    .get(this.masterDataAPI + '/district/details/'+id+'/division/'+ division_id)
    .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
}
createDistrict(body: any): Observable<any> {
  return this.httpService
    .post(this.masterDataAPI + '/district/add', body)
    .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
}
 
// End District

// Sub-District
getSubDistricts(): Observable<any> {
  return this.httpService
    .get(this.masterDataAPI + '/sub-district/all')
    .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
}

getSubDistrictsByDistrict(district_id): Observable<any> {
  return this.httpService
    .get(this.masterDataAPI + '/sub-district/all/district/'+ district_id)
    .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
}

getDetailsSubDistrict(district_id, id): Observable<any> {
  return this.httpService
    .get(this.masterDataAPI + '/sub-district/details/'+id+'/district/'+ district_id)
    .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
}
createSubDistrict(body: any): Observable<any> {
  return this.httpService
    .post(this.masterDataAPI + '/sub-district/add', body)
    .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
}
 
// End Sub-District

//Union
getUnions(): Observable<any> {
  return this.httpService
    .get(this.masterDataAPI + '/union/all')
    .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
}

getUnionsBySubDistrict(subdistrict_id): Observable<any> {
  return this.httpService
    .get(this.masterDataAPI + '/union/all/sub-district/'+ subdistrict_id)
    .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
}

getDetailsUnion(district_id, id): Observable<any> {
  return this.httpService
    .get(this.masterDataAPI + '/union/details/'+id+'/district/'+ district_id)
    .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
}
createUnion(body: any): Observable<any> {
  return this.httpService
    .post(this.masterDataAPI + '/union/add', body)
    .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
}
 
// End  Union

 
//Ward
getWards(): Observable<any> {
  return this.httpService
    .get(this.masterDataAPI + '/ward/all')
    .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
}

getWardsByUnion(union_id): Observable<any> {
  return this.httpService
    .get(this.masterDataAPI + '/ward/all/union/'+ union_id)
    .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
}

getDetailsWard(id): Observable<any> {
  return this.httpService
    .get(this.masterDataAPI + '/ward/details/'+id)
    .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
}
createWard(body: any): Observable<any> {
  return this.httpService
    .post(this.masterDataAPI + '/ward/add', body)
    .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
}
updateWard(district_id, id, body: any): Observable<any> {
  return this.httpService
    .post(this.masterDataAPI + '/ward/update?district_id=' + district_id + '&id=' + id, body)
    .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
}
// End  Ward  
}
