import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FileUploadSubCategory } from '../../enum/file-upload-sub-category.enum';
import { Url } from '../../enum/url.enum';
import { HttpRequestService } from '../http-request/http-request.service';
import { UtilitiesService } from '../utilities/utilities.service';

@Injectable({
  providedIn: 'root',
})
export class UploaderService {
  masterDataUploadAPIOld = Url.MASTER_DATA_UPLOAD + '/masterdata/upload';
  masterDataUploadAPI = Url.MASTER_DATA_UPLOAD + '/master-data/upload';

  userBulkUploadAPI = Url.AUTH + '/bulk-upload/user';
  staffMappingBulkUploadAPI = Url.AUTH + '/bulk-upload/staff-mapping';

  fileUploadAPI = Url.FILE_UPLOAD + '/files';
  geoLocationUploadAPI = Url.CONFIGURATION + '/bulk-upload/geo-location';
  configBulkUploadAPI = Url.CONFIGURATION + '/bulk-upload/';

  constructor(
    private utilitiesService: UtilitiesService,
    private httpService: HttpRequestService,
  ) {}

  public uploadFiles(files) {
    const formData = new FormData();
    formData.append('Content-Type', 'multipart/form-data');
    // files.forEach((element) => {
    //   formData.append('files', element);
    // });
    for (let i = 0; i < files.length; i++) { 
      formData.append('files', files[i]);
    }
    return this.httpService
      .post(this.fileUploadAPI + '/upload', formData)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public deleteFile(resourceId: string, fileId: string) {
    return this.httpService
      .delete(
        this.fileUploadAPI +
          '/resources/' +
          resourceId +
          '/' +
          fileId +
          '/remove',
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public uploadMasterDataFile(file, typeModule) {
    const formData = new FormData();
    formData.append('file', file);

    let apiUrl: any;
    if (typeModule === FileUploadSubCategory.USERS) {
      apiUrl = this.userBulkUploadAPI;
    } else if (typeModule === FileUploadSubCategory.STAFF_MAPPING) {
      apiUrl = this.staffMappingBulkUploadAPI;
    } else if (typeModule === FileUploadSubCategory.GEO_LOCATION) {
      apiUrl = this.geoLocationUploadAPI;
    } else if (typeModule === FileUploadSubCategory.PROJECT_OFFICE_MANAGEMENT) {
      apiUrl = this.configBulkUploadAPI + typeModule;
    } else if (typeModule === FileUploadSubCategory.ADDITIONAL_FIELD_STRUCTURE) {
      apiUrl = this.configBulkUploadAPI + typeModule;
    } else if (typeModule === FileUploadSubCategory.TARGET_SETUP) {
      apiUrl = this.configBulkUploadAPI + typeModule;
    } else {
      apiUrl = this.masterDataUploadAPI + '/' + typeModule;
    }
    return this.httpService.post(apiUrl, formData).pipe(
      map((x: any) => this.utilitiesService.responseHandler(x)),
      catchError((error: Response) => throwError(() => error)),
    );
  }
}
